import React, { useState, useCallback } from 'react';

import axios from 'axios';
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/scss/main.scss';

import { logout } from './authProvider';

export const getQueryParameters = function (str) {
  return (str || document.location.search)
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      function (n) {
        return (n = n.split(/=(.+)/)), (this[n[0]] = n[1]), this; // eslint-disable-line no-sequences
      }.bind({})
    )[0];
};

export const pushToDataLayer = (data) => {
  const { dataLayer } = window;

  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({ ...data });
  }
};

export function useLocalStorage(key, initialState) {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState);
  const updatedSetValue = useCallback(
    (newValue) => {
      if (newValue === initialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, newValue);
      }
      setValue(newValue ?? initialState);
    },
    [initialState, key]
  );
  return [value, updatedSetValue];
}

export const setRedirectionPathname = () => {
  window.location.pathname !== '/logout' &&
    sessionStorage.setItem(
      'pathname-before-redirection',
      JSON.stringify({
        pathname: window.location.pathname,
        time: Date.now(),
      })
    );
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getSizeDocument = async (documentLink) => {
  //Retourne le taille d'un document en ko
  const res = await axios({
    url: documentLink,
    method: 'GET',
    responseType: 'blob',
  });
  return parseInt(res.data.size / 1000);
};

export const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;

  if (h === '00') {
    return `${m} min`;
  } else {
    return `${h}h${m}`;
  }
};

export const convertSerializeArrayToObject = function (serialize) {
  let data = {};
  $(serialize).each(function (index, obj) {
    data[obj.name + ''] = obj.value;
  });
  return data;
};

export const sortQuestionWithPlatform = (questions, plateform) => {
  let questionsTab = [];
  questions.forEach((question, index) => {
    if (question.platforms.length === 0) {
      //questionsTab.push(question);
    } else {
      question.platforms.forEach((platform, index) => {
        if (platform.name === plateform) questionsTab.push(question);
      });
    }
  });
  return questionsTab;
};

export const catchErrors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let messageCode =
        error?.response?.data?.message ||
        error?.response?.data?.Message ||
        null;
      let errorMessage;

      if (error?.response?.status === 401) {
        logout();
        window.location.href = '/';
      }

      if (error?.response?.data?.noPopup || error?.response?.status === 400)
        return Promise.reject(error);

      switch (error?.response?.status) {
        case 401:
          errorMessage =
            "Erreur : L'accès à la ressource demandée n'a pas été autorisée.";
          break;
        case 403:
          errorMessage = "Erreur : L'accès à cette page vous est interdit.";
          break;
        case 405:
          errorMessage =
            'Erreur : La méthode utilisée dans la requête est refusée par le serveur.';
          break;
        case 407:
          errorMessage = 'Erreur : Autorisation du proxy nécessaire.';
          break;
        case 408:
          errorMessage = 'Erreur : Temps d’accès à la page demandée expiré.';
          break;
        case 410:
          errorMessage = 'Erreur : Cette ressource n’est plus disponible';
          break;
        case 429:
          errorMessage = 'Erreur : Trop de requêtes.';
          break;
        case 500:
          errorMessage =
            "Erreur : La requête envoyée par le navigateur n'a pas pu être traitée.";
          break;
        default:
          errorMessage = 'Une erreur est survenue';
          break;
      }

      if (messageCode !== null) errorMessage = 'Erreur : ' + messageCode;

      toast.error(
        <div>
          <i className="fas fa-exclamation-triangle"></i>
          {errorMessage}
        </div>,
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: errorMessage,
          icon: false,
        }
      );
      return Promise.reject(error);
    }
  );
};

export const openOnetrustInfo = () => {
  let OneTrust = global.OneTrust;
  if (typeof OneTrust !== 'object') return;

  OneTrust.ToggleInfoDisplay();
};

export const getEnvironment = () => {
  let environment = '';

  switch (window.location.hostname) {
    case 'espace-client.front':
      environment = 'local';
      break;
    case 'espace-client-rec.prowebce.net':
      environment = 'recette';
      break;
    case 'espace-client-pprd.prowebce.net':
      environment = 'pre-prod';
      break;
    case 'espace-client.prowebce.net':
      environment = 'prod';
      break;
    default:
      environment = '';
      break;
  }

  return environment;
};

export const convertSearchToObject = (search) => {
  const params = {};
  const searchParams = new URLSearchParams(search);
  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};

export const apiCallWithToken = (url, method, data = {}) => {
  const token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));

  return axios({
    url: `${process.env.REACT_APP_BACK_URL}${url}`,
    method: method,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const formDataRequest = (url, method, data = {}, withToken = true) => {
  const token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));

  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };

  return axios({
    url: `${process.env.REACT_APP_BACK_URL}${url}`,
    method: method,
    data: data,
    headers: headers,
  });
};

export const apiCall = (url, method, data = {}) => {
  return axios({
    url: `${process.env.REACT_APP_BACK_URL}${url}`,
    method: method,
    data: data,
  });
};

export const isLoadingQueries = (queries) => {
  let isLoading = false;
  queries.forEach((query) => {
    if (query.isLoading && query.isFetching) {
      isLoading = true;
    }
  });
  return isLoading;
};

export const convertTimeToMs = (time, type) => {
  let ms = 0;
  switch (type) {
    case 'day':
      ms = 1000 * 60 * 60 * 24;
      break;
    case 'hour':
      ms = 1000 * 60 * 60;
      break;
    case 'minute':
      ms = 1000 * 60;
      break;
    case 'second':
      ms = 1000;
      break;
    default:
      ms = 0;
      break;
  }
  return ms * time;
};

export const formatPrice = (price) => {
  price = parseFloat(price);
  return price.toFixed(2).replace('.', ',');
};

export const escapeHTML = (str) => {
  if (typeof str !== 'string') return str;
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/<script.*?>.*?<\/script>/gim, ''); // Supprime les balises script
};
