import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';

import CardRdv from '@components/Card_rdv/card_rdv.jsx';

import PeopleMeetPng from '@img/peopleMeet.png';

import ModalCancel from '../modalCancelAppointment/modalCancelAppointment';
import ModalInfoAppointment from '../modalInfoAppointment/modalInfoAppointment';
import ModalPlace from '../modalPlaceAppointment/modalPlaceAppointment';

import Carousel from './carousel/carousel';
import './prochain_rdv.scss';

export default function Prochain_rdv({ data, loading, removeRdv }) {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [RdvSuivantData, setRdvSuivantData] = useState(null);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [dataModalCancel, setDataModalCancel] = useState(null);
  const [showModalPlace, setShowModalPlace] = useState(false);
  const [dataModalPlace, setDataModalPlace] = useState(null);

  useEffect(() => {
    if (!loading && data) {
      let RdvSuivantArray = [...data];
      RdvSuivantArray.splice(0, 1);
      setRdvSuivantData(RdvSuivantArray);
    }
  }, [data, loading]);

  const onClickCancel = (id) => {
    setShowModalCancel(true);
    setShowModal(false);
    setDataModalCancel(data.find((item) => item.id === id));
  };

  return (
    <div className="row">
      <div className="col-12 rect_info rect_info_search_bill">
        {loading ? (
          <div className="col-12 prochain_rdv">
            <div className="row prochain_rdv_row">
              <div className="col-12">
                <h5 className="fw-bold text-primary rect_info_title skeleton">
                  <section className="d-flex flex-column align-items-center justify-center w-100">
                    <Skeleton height={20} width="30%" className="mb-4" />
                    <Skeleton height={20} width="50%" className="mb-2" />
                    <Skeleton height={20} width="70%" className="mb-2" />
                    <Skeleton height={20} width="65%" className="" />
                  </section>
                </h5>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 prochain_rdv">
            <div className="row prochain_rdv_row">
              <div className="col-12 col-lg-7">
                <h5
                  className="fw-bold text-primary rect_info_title"
                  data-cy="prochain-rdv-title"
                >
                  Prochain rendez-vous
                </h5>
                <div className="content">
                  {data && (
                    <CardRdv
                      data={data[0]}
                      setShowModalPlace={setShowModalPlace}
                      setDataModalPlace={setDataModalPlace}
                      onClickCancel={() => {
                        setShowModalCancel(true);
                        setDataModalCancel(data[0]);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 d-none d-lg-block img_people">
                <img src={PeopleMeetPng} alt="Logo" />
              </div>
            </div>
            {RdvSuivantData?.length ? (
              <div className="row" data-cy="rdv-suivants">
                <div className="col-12">
                  <h5 className="fw-bold text-primary rect_info_title rect_info_title_rdv_suivants mt-4">
                    Rendez-vous suivants
                  </h5>

                  <Carousel
                    showModal={showModal}
                    setModalData={setModalData}
                    setShowModal={setShowModal}
                    setShowModalPlace={setShowModalPlace}
                    data={RdvSuivantData}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {data && (
              <ModalCancel
                showModal={showModalCancel}
                setShowModal={setShowModalCancel}
                data={dataModalCancel}
                removeRdv={removeRdv}
              />
            )}
          </div>
        )}
      </div>
      <ModalInfoAppointment
        showModal={showModal}
        setShowModal={setShowModal}
        setShowModalPlace={setShowModalPlace}
        data={modalData}
        onClickCancel={onClickCancel}
        setDataModalPlace={setDataModalPlace}
      />
      <ModalPlace
        showModal={showModalPlace}
        setShowModalInfo={setShowModal}
        setShowModal={setShowModalPlace}
        data={dataModalPlace}
      />
    </div>
  );
}
