import React, { useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Checkbox } from '@atoms';

import { ReactComponent as Alert } from '@img/icons/alert-icon-not-full.svg';

import LoadingButton from '../LoadingButton/LoadingButton';

import './Booking.scss';
import BookingCarousel from './BookingCarousel';

dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isBetween'));

export default function Booking({
  onClickNext,
  date,
  setDate,
  format,
  motif,
  buttonName = 'Suivant',
  loadingButton = false,
  isDeadline = false,
  infoResponsable,
}) {
  const [conditionChecked, setConditionChecked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div className={`booking`}>
      <BookingCarousel
        date={date}
        setDate={setDate}
        format={format}
        motif={motif}
        setIsSelected={setIsSelected}
      />
      {isDeadline && (
        <React.Fragment>
          <div className="deadline-warning">
            <div className="side-rect">
              <Alert />
            </div>
            <div className="content">
              <div className="body-text">
                Compte tenu que l’heure de votre rendez-vous est très proche,
                nous vous invitons aussi à contacter votre interlocuteur par
                téléphone afin de le prévenir du report du rendez-vous.
              </div>
              {infoResponsable && infoResponsable.telephone && (
                <div className="tel-number">
                  Voici son numéro :{' '}
                  <span>{infoResponsable && infoResponsable.telephone}</span>
                </div>
              )}
            </div>
          </div>
          <Checkbox
            label="Je confirme avoir prévenu mon interlocuteur de l’annulation du rendez-vous."
            name="condition"
            id="condition"
            data-cy="deadline-warning-input-check"
            checked={conditionChecked}
            onChange={() => setConditionChecked(!conditionChecked)}
          />
        </React.Fragment>
      )}
      <div className="text-center button mt-3">
        <LoadingButton
          type="submit"
          classes={`btn btn-primary rounded-pill fw-600 ${
            (isDeadline && !conditionChecked) || !isSelected ? 'disabled' : ''
          }`}
          text={buttonName}
          onClick={onClickNext}
          loading={loadingButton}
          spinnerHeight="20"
          disabled={
            (isDeadline && !conditionChecked) || !isSelected ? 'disabled' : ''
          }
          dataCy="submit-button-next"
        />
      </div>
    </div>
  );
}
