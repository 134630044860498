import React, { useState, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Checkbox, Radio } from '@atoms';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import {
  useSkynetMainResponsable,
  useSkynetCancelSubmotifList,
  useSkynetPutActionCancel,
} from '@hooks/useApi';

import { ReactComponent as Alert } from '@img/icons/alert-icon-not-full.svg';

import './modalCancelAppointment.scss';

export default function ModalCancelAppointment({
  showModal,
  setShowModal,
  data,
}) {
  const [radioValue, setRadioValue] = useState(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [hasTextarea, setHasTextarea] = useState(false);
  const [congratModal, setCongratModal] = useState(false);
  const [isDeadline, setIsDeadline] = useState(false);
  const [conditionChecked, setConditionChecked] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: dataCancelSubmotifList,
    isLoading: isLoadingCancelSubmotifList,
  } = useSkynetCancelSubmotifList({
    staleTime: Infinity,
    enable: showModal === true,
  });

  const { data: dataMainResponsable, isLoading: isLoadingMainResponsable } =
    useSkynetMainResponsable({
      enable: showModal === true,
    });

  const { mutate: mutatePutActionCancel, isLoading: isLoadingPutActionCancel } =
    useSkynetPutActionCancel({
      onSuccess: (data) => {
        setConditionChecked(false);
        setCongratModal(true);
        setHasTextarea(false);
        setRadioValue(null);
        setTextareaValue('');

        queryClient.invalidateQueries({
          queryKey: ['skynet-actions-list'],
        });

        queryClient.invalidateQueries({
          queryKey: ['skynet-next-rdv'],
        });

        queryClient.invalidateQueries({
          queryKey: ['data-distance'],
        });

        queryClient.invalidateQueries({
          queryKey: ['data-presentiel'],
        });
      },
    });

  useEffect(() => {
    data && setIsDeadline(data.isDeadline);
  }, [data]);

  useEffect(() => {
    if (dataCancelSubmotifList && dataCancelSubmotifList['hydra:member']) {
      const find = dataCancelSubmotifList['hydra:member'].find(
        (element) => element.name === radioValue
      );
      if (isDeadline && !conditionChecked) {
        setButtonIsDisabled(true);
      } else {
        if (find && find.customField) {
          textareaValue.trim() === ''
            ? setButtonIsDisabled(true)
            : setButtonIsDisabled(false);
        } else {
          setButtonIsDisabled(true);

          if (radioValue && radioValue !== 'not-available') {
            setButtonIsDisabled(false);
          } else {
            setButtonIsDisabled(true);
          }
        }
      }
    }
  }, [radioValue, textareaValue, conditionChecked, isDeadline]);

  const onChangeInput = (e) => {
    setRadioValue(e.target.value);
    setTextareaValue('');
    setHasTextarea(
      dataCancelSubmotifList['hydra:member'].find(
        (item) => item.name === e.target.value
      ).customField
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const find = dataCancelSubmotifList['hydra:member'].find(
      (element) => element.name === radioValue
    );

    mutatePutActionCancel({
      id: data.id,
      motif: find.id,
      message: find.customField ? textareaValue : radioValue,
    });
  };

  return (
    <Modal
      show={showModal}
      handleClose={() => {
        setShowModal(false);
        setRadioValue('not-available');
        setHasTextarea(false);
        setTextareaValue('');
        setConditionChecked(false);
      }}
      className="modal_cancel_rdv"
      noCloseButton={congratModal}
      noHeader={congratModal}
    >
      {!congratModal ? (
        <div className="">
          <h2 className="fw-bold text-primary text-center">
            Annuler le rendez-vous
          </h2>

          <p className="text-primary">
            Vous souhaitez annuler votre rendez-vous du{' '}
            <strong>
              {data && dayjs(data.dateDebut).format('DD/MM/YYYY')}{' '}
            </strong>
            à{' '}
            <strong>
              {data && dayjs(data.dateDebut).format('HH:mm').replace(':', 'h')}
            </strong>{' '}
            avec <strong>{data && data.utilisateursAssignes[0]}</strong>.
          </p>

          <form onSubmit={(e) => onSubmit(e)} className="form-motif">
            <p className="text-primary motif-title">
              Quel est le motif de votre annulation ?
            </p>
            {isLoadingCancelSubmotifList || isLoadingMainResponsable ? (
              <SkeletonTheme width="100%" height="100%">
                <section className="d-flex flex-column align-items-center justify-center w-100 skeleton">
                  <Skeleton height={20} width="100%" className="mb-3" />
                  <Skeleton height={20} width="100%" className="mb-3" />
                  <Skeleton height={20} width="100%" className="mb-4" />
                </section>
              </SkeletonTheme>
            ) : (
              dataCancelSubmotifList &&
              dataCancelSubmotifList['hydra:member'] &&
              dataCancelSubmotifList['hydra:member'].map((motifItem, index) => {
                return (
                  <div
                    key={`inputRadioMotif${index}`}
                    data-has-textarea={motifItem.customField.toString()}
                    className="mb-3"
                  >
                    <Radio
                      label={motifItem.name}
                      name="radioMotif"
                      id={`radioMotif${index}`}
                      value={motifItem.name}
                      checked={radioValue === motifItem.name}
                      data-id-motif={index}
                      onChange={(e) => onChangeInput(e)}
                    />
                  </div>
                );
              })
            )}
            {hasTextarea && (
              <div className="textarea-box">
                <p className="text-primary motif-title">
                  Pouvez-vous précisez ? <i>(obligatoire)</i>
                </p>
                <textarea
                  className="form-control h3 text-primary"
                  id="formControlTextarea1"
                  value={textareaValue}
                  rows="4"
                  required
                  onChange={(e) => setTextareaValue(e.target.value)}
                  data-cy="textarea-cancel"
                ></textarea>
              </div>
            )}

            {isDeadline && (
              <React.Fragment>
                <div className="deadline-warning">
                  <div className="side-rect">
                    <Alert />
                  </div>
                  <div className="content">
                    <div className="body-text">
                      Compte tenu que l’heure de votre rendez-vous est très
                      proche, nous vous invitons aussi à contacter votre
                      interlocuteur par téléphone afin de le prévenir de
                      l’annulation du rendez-vous.
                    </div>
                    <div className="tel-number">
                      Voici son numéro :{' '}
                      <span>
                        {dataMainResponsable && dataMainResponsable.telephone}
                      </span>
                    </div>
                  </div>
                </div>

                <Checkbox
                  label="Je confirme avoir prévenu mon interlocuteur de l’annulation du rendez-vous.."
                  name="condition"
                  id="condition"
                  data-cy="deadline-warning-input-check"
                  checked={conditionChecked}
                  onChange={() => setConditionChecked(!conditionChecked)}
                />
              </React.Fragment>
            )}

            <div className="text-center button">
              <LoadingButton
                text="Annuler le rendez-vous"
                loading={isLoadingPutActionCancel}
                spinnerHeight="20"
                type="submit"
                classes={`btn btn-primary rounded-pill fw-600 ${
                  buttonIsDisabled ? 'disabled' : ''
                }`}
                disabled={buttonIsDisabled ? 'disabled' : ''}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="congrat-cancel">
          <h2 className="fw-bold text-primary text-center">
            Rendez-vous annulé
          </h2>

          <p className="text-primary">
            <span>Vous allez être notifié par e-mail.</span>
            <span>
              Nous espérons vous revoir bientôt pour un autre rendez-vous.
            </span>
          </p>

          <div className="text-center button">
            <button
              type="button"
              className={`btn btn-primary rounded-pill fw-600`}
              onClick={() => {
                setCongratModal(false);
                setShowModal(false);
                setConditionChecked(false);
              }}
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
