import React from 'react';

import PropTypes from 'prop-types';

import './button.scss';

export const Button = ({
  style = 'primary',
  backgroundColor,
  className,
  size,
  label,
  startIcon,
  endIcon,
  children,
  ...props
}) => {
  return (
    <button
      className={[
        'btn',
        'btn-ec',
        'rounded-pill',
        `btn-${style}`,
        startIcon || endIcon ? 'd-inline-flex gap-2 align-items-center' : '',
        className,
      ].join(' ')}
      style={{
        backgroundColor,
        fontWeight: 600,
      }}
      {...props}
    >
      {startIcon}
      {label}
      {children}
      {endIcon}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Additional class names
   */
  className: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Icon to display on the left
   */
  startIcon: PropTypes.element,
  /**
   * Icon to display on the right
   */
  endIcon: PropTypes.element,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  style: 'primary',
  size: 'medium',
  className: '',
  startIcon: null,
  endIcon: null,
  onClick: undefined,
};
