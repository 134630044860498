import React from 'react';

import { Notification } from '@molecules';

import { ReactComponent as InfoNotification } from '@img/icons/infoNotification.svg';

import './NotificationBanner.scss';

export default function NotificationBanner({
  title,
  content,
  link,
  linkText,
  badge,
  color,
  ...props
}) {
  return (
    <div className="notification-banner-container">
      <div
        className={`notification-banner w-100 h-100 d-block d-md-inline-block `}
      >
        <Notification
          title={title}
          text={content}
          link={link}
          linkText={linkText}
          borderColor={color}
          className="h-100"
          icon={
            badge ? (
              <div className="badge bg-new text-light">{badge}</div>
            ) : (
              <InfoNotification className="info-icon mt-2 mt-md-0" />
            )
          }
          {...props}
        />
      </div>
    </div>
  );
}
