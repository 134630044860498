import React, { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';

import { useSkynetEquipmentClient } from '@hooks/useApi';

import { ReactComponent as ChequeCadeau } from '@img/icons/cheque_cadeau_icon.svg';
import { ReactComponent as Premium } from '@img/icons/premium.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import { ReactComponent as Smart } from '@img/icons/smart.svg';
import { ReactComponent as Start } from '@img/icons/start.svg';
import { ReactComponent as Subventions } from '@img/icons/subventions.svg';

import { UserContext } from '../../context/UserContext';
import {
  useContractRenewalDate,
  useSubsidyWalletAmountRequest,
} from '../../hooks/useApi';
import { useGiftVoucherBalanceRequest } from '../../hooks/useApi';

import ModalChequeCadeau from './ModalChequeCadeau';
import ModalContactCDC from './ModalContactCDC';
import ModalService from './ModalServices';
import ModalSubvention from './ModalSubvention';
import Carousel from './carousel';
import './equipment.scss';

export default function Equipment() {
  const [showModalService, setShowModalService] = useState(false);
  const [clickedService, setClickedService] = useState(null);
  const [showModalSubvention, setShowModalSubvention] = useState(false);
  const [clickedSubvention, setClickedSubvention] = useState(null);
  const [showModalChequeCadeau, setShowModalChequeCadeau] = useState(false);
  const [showModalContactCDC, setShowModalContactCDC] = useState(false);
  const { user } = useContext(UserContext);
  const [subsidyWalletAmount, setSubsidyWalletAmount] = useState(null);
  const [contractRenewalDate, setContractRenewalDate] = useState(null);

  const {
    data: equipmentClient,
    isInitialLoading: equipmentClientIsInitialLoading,
  } = useSkynetEquipmentClient();

  const {
    data: subsidyWalletAmountData,
    isError: subsidyWalletAmountError,
    isInitialLoading: subsidyWalletAmountIsInitialLoading,
  } = useSubsidyWalletAmountRequest();

  const {
    data: giftVoucherBalanceData,
    error: giftVoucherBalanceErrorData,
    isError: giftVoucherBalanceError,
    isInitialLoading: giftVoucherBalanceIsInitialLoading,
  } = useGiftVoucherBalanceRequest({
    enabled: !!user?.meyClubId,
  });

  const {
    data: contractRenewalDateData,
    isError: contractRenewalDateError,
    isInitialLoading: contractRenewalDateIsInitialLoading,
  } = useContractRenewalDate({
    enabled: !!user?.meyClubId,
  });

  const handleClose = () => {
    showModalService && setShowModalService(false);
    showModalSubvention && setShowModalSubvention(false);
    showModalChequeCadeau && setShowModalChequeCadeau(false);
    showModalContactCDC && setShowModalContactCDC(false);
  };
  const handleShowModalService = () => {
    setShowModalService(true);
  };
  const handleShowModalSubvention = () => {
    setShowModalSubvention(true);
  };
  const handleShowModalContactCDC = () => {
    setShowModalContactCDC(true);
  };
  const handleShowModalChequeCadeau = () => {
    setShowModalChequeCadeau(true);
  };

  let optionsService = [
    {
      value: {
        texte_mail: "par un service d'accompagnement en gestion",
        isSelectedService:
          equipmentClientIsInitialLoading &&
          equipmentClient?.accompagnementGestion,
      },
      label: 'Accompagnement en gestion',
    },
    {
      value: {
        texte_mail: "par un service d'accompagnement en communication",
        isSelectedService:
          equipmentClientIsInitialLoading &&
          equipmentClient?.accompagnementComm,
      },
      label: 'Accompagnement en communication',
    },
    {
      value: {
        texte_mail:
          "par un service d'accompagnement en gestion + communication",
        isSelectedService:
          equipmentClientIsInitialLoading &&
          equipmentClient?.accompagnementGestion &&
          equipmentClient?.accompagnementComm
            ? true
            : false,
      },
      label: 'Accompagnement gestion + communication',
    },
    {
      value: {
        texte_mail: 'par un service de formation',
        isSelectedService: false,
      },
      label: 'Formation',
    },
    {
      value: {
        texte_mail: 'par un service de conseil',
        isSelectedService: false,
      },
      label: 'Conseil',
    },
  ];

  let optionsCDC = [
    {
      value: {
        texte_mail: 'pour la mise en place d’un Compte Culture et Loisirs',
      },
      label: 'Compte Culture et Loisirs',
    },
    {
      value: {
        texte_mail: 'pour la mise en place d’un Compte Vacances',
      },
      label: 'Compte Vacances',
    },
    {
      value: {
        texte_mail: 'pour la mise en place d’un Compte Sport',
      },
      label: 'Compte Sport',
    },
    {
      value: {
        texte_mail: 'pour la mise en place d’un Compte Liberté',
      },
      label: 'Compte Liberté',
    },
    {
      value: {
        texte_mail: '',
      },
      label: 'Chèque cadeau',
    },
  ];

  const getDataAndShowModalServices = (service) => {
    let serviceData = optionsService.find((option) => option.label === service);
    setClickedService(serviceData);
    handleShowModalService();
  };

  const getDataAndShowModalSubvention = () => {
    handleClose();
    handleShowModalSubvention();
  };

  const getDataAndShowModalContactCDC = (label) => {
    let optionData = optionsCDC.find((option) => option.label === label);
    handleClose();
    setClickedSubvention(optionData);
    handleShowModalContactCDC();
  };

  const returnModalContactCDC = (returnModal) => {
    handleClose();
    returnModal === 'subvention'
      ? getDataAndShowModalSubvention()
      : handleShowModalChequeCadeau();
  };

  useEffect(() => {
    setSubsidyWalletAmount(subsidyWalletAmountData?.value ?? 0);

    if (contractRenewalDateData?.endAt) {
      setContractRenewalDate(
        dayjs(contractRenewalDateData?.endAt).format('DD/MM/YYYY')
      );
    }
  }, [
    subsidyWalletAmountIsInitialLoading,
    giftVoucherBalanceIsInitialLoading,
    contractRenewalDateIsInitialLoading,
  ]);

  const isPageLoading =
    equipmentClientIsInitialLoading ||
    giftVoucherBalanceIsInitialLoading ||
    subsidyWalletAmountIsInitialLoading ||
    contractRenewalDateIsInitialLoading;

  return (
    <div className="w-100 equipment">
      <h3 className="title_page">Mon équipement</h3>
      <ModalService
        show={showModalService}
        handleClose={handleClose}
        options={!equipmentClientIsInitialLoading ? optionsService : []}
        clickedService={clickedService}
      />
      <ModalSubvention
        show={showModalSubvention}
        handleClose={handleClose}
        getDataAndShowModalContactCDC={getDataAndShowModalContactCDC}
      />
      <ModalChequeCadeau
        show={showModalChequeCadeau}
        handleClose={handleClose}
        getDataAndShowModalContactCDC={getDataAndShowModalContactCDC}
      />
      <ModalContactCDC
        show={showModalContactCDC}
        handleClose={handleClose}
        clickedSubvention={clickedSubvention}
        returnModalContactCDC={returnModalContactCDC}
      />

      <div className="row justify-content-between">
        {isPageLoading ||
        (equipmentClient &&
          (equipmentClient?.licence ||
            equipmentClient?.logiciel ||
            equipmentClient?.meyclub)) ? (
          <div
            className="col-xl-4 rect_info rect_info_licence"
            data-cy="equipment-insert"
          >
            <div className="col-12 h-100 ">
              <h5 className="fw-bold text-primary rect_info_title">
                {equipmentClient && equipmentClient?.licence ? (
                  'Licence'
                ) : equipmentClient?.logiciel || equipmentClient?.meyclub ? (
                  'À la carte'
                ) : (
                  <Skeleton color="#e6e6e6" width={80} />
                )}
              </h5>
              <h5 className="fw-bold text-primary licence_type">
                {equipmentClient && equipmentClient?.licence && (
                  <>
                    <span>
                      {equipmentClient?.licence === 'Start' ? (
                        <Start className="" />
                      ) : equipmentClient?.licence === 'Smart' ? (
                        <Smart className="" />
                      ) : (
                        <Premium className="" />
                      )}
                      {equipmentClient?.licence}
                    </span>
                  </>
                )}
                <span>
                  {equipmentClient?.logiciel &&
                    'Logiciel à la carte Com/Gestion'}
                </span>
                <span>{equipmentClient?.meyclub && 'Meyclub'}</span>
                {equipmentClient?.licence === undefined && (
                  <Skeleton color="#e6e6e6" width={240} />
                )}
              </h5>
              {!contractRenewalDateIsInitialLoading &&
                !contractRenewalDateError &&
                user.meyClubId && (
                  <div
                    className="text-center mt-4"
                    data-cy="contract-renewal-date"
                  >
                    Date de renouvellement de votre licence :{' '}
                    {contractRenewalDate}
                  </div>
                )}
              <div className="mt-4 d-none d-xl-block"></div>
            </div>
          </div>
        ) : (
          ''
        )}

        <div
          className="col-md-6 col-xl-4 rect_info"
          data-cy="equipment-insert"
          data-test-id="subsidy-wallet"
        >
          <div className="col-12 h-100 d-flex flex-column justify-content-between position-relative">
            {isPageLoading ? (
              <div className="w-100 skeleton">
                <section className="d-flex flex-column align-items-center justify-center w-100 ">
                  <Skeleton height={20} width="100%" className="mb-4" />
                  <Skeleton height={20} width="100%" className="mb-1" />
                  <Skeleton height={20} width="80%" className="mb-4" />
                  <Skeleton
                    height={36}
                    width="100%"
                    className="mb-3 rounded-pill button"
                  />
                </section>
              </div>
            ) : (
              <>
                <h5 className="fw-bold text-primary d-flex align-items-end rect_info_title">
                  <Subventions className="stroke-primary" />
                  Subventions
                </h5>
                {user?.meyClubId && (
                  <div
                    className={`bg-success rounded-2 text-white text-center fs-7 font-weight-600 p-1 mb-3 ${
                      subsidyWalletAmountIsInitialLoading ? 'invisible' : ''
                    }`}
                    data-cy="subsidy-wallet-amount"
                  >
                    Solde :
                    <span className="ps-1">
                      {!subsidyWalletAmountError
                        ? subsidyWalletAmount
                        : 'N.C €'}
                    </span>
                  </div>
                )}
                {equipmentClient?.subvCreditCumul ? (
                  <p className="body">
                    Vous avez mis en place au moins{' '}
                    <strong>1 subvention</strong> cette année.
                  </p>
                ) : (
                  <p className="body">
                    Vous n'avez pas mis en place de nouvelles subventions cette
                    année.
                    <div className="d-none d-md-inline ms-1">
                      <Question
                        className="stroke-primary questionIcon"
                        data-tip
                        data-for="questionSubventions"
                      />
                      <ReactTooltip
                        id="questionSubventions"
                        place="left"
                        type="info"
                        effect="solid"
                        className="reactTooltipEquipment"
                      >
                        <span>
                          Cela ne signifie pas que vous n'avez pas de subvention
                          active mais que vous n'avez pas créé de nouvelle
                          subvention cette année (calendaire).
                        </span>
                      </ReactTooltip>
                    </div>
                  </p>
                )}
                <button
                  type="button"
                  onClick={getDataAndShowModalSubvention}
                  className="btn btn-primary rounded-pill btn-block"
                >
                  Créer une nouvelle subvention
                </button>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 col-xl-4 rect_info" data-cy="equipment-insert">
          <div
            className="col-12 h-100 d-flex flex-column justify-content-between position-relative"
            data-test-id="gift-vouchers"
          >
            {isPageLoading ? (
              <div className="w-100 skeleton">
                <section className="d-flex flex-column align-items-center justify-center w-100">
                  <Skeleton height={20} width="100%" className="mb-4" />
                  <Skeleton height={20} width="100%" className="mb-1" />
                  <Skeleton height={20} width="80%" className="mb-4" />
                  <Skeleton
                    height={36}
                    width="100%"
                    className="mb-3 rounded-pill button"
                  />
                </section>
              </div>
            ) : (
              <>
                <h5 className="fw-bold text-primary d-flex align-items-end rect_info_title">
                  <ChequeCadeau className="stroke-primary" />
                  Chèques cadeaux
                </h5>

                {user.meyClubId && (
                  <div
                    className={`bg-success rounded-2 text-white text-center fs-7 font-weight-600 p-1 mb-3 ${
                      giftVoucherBalanceIsInitialLoading ? 'invisible' : ''
                    }`}
                    data-cy="gift-voucher-balance"
                  >
                    Solde :
                    <span className="ps-1">
                      {giftVoucherBalanceError
                        ? giftVoucherBalanceErrorData?.response?.data?.value
                            ?.value
                        : giftVoucherBalanceData?.value}
                    </span>
                  </div>
                )}
                {equipmentClient?.ccmcCrediteCumul ? (
                  <p className="body">
                    Vous avez mis en place au moins{' '}
                    <strong>1 campagne de chèque cadeau</strong> cette année.
                  </p>
                ) : (
                  <p className="body">
                    Vous n’avez pas mis en place de nouvelles campagnes de
                    chèques cadeaux cette année.
                    <div className="d-none d-md-inline ms-1">
                      <Question
                        className="stroke-primary questionIcon"
                        data-tip
                        data-for="questionChequesCadeaux"
                      />
                      <ReactTooltip
                        id="questionChequesCadeaux"
                        place="left"
                        type="info"
                        effect="solid"
                        className="reactTooltipEquipment"
                      >
                        <span>
                          Cela ne signifie pas que vous n'avez pas de campagne
                          en cours mais que vous n'avez pas créé de nouvelle
                          campagne cette année (calendaire).
                        </span>
                      </ReactTooltip>
                    </div>
                  </p>
                )}
                <button
                  type="button"
                  onClick={() => setShowModalChequeCadeau(true)}
                  className="btn btn-primary rounded-pill btn-block"
                >
                  Créer une nouvelle campagne
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 rect_info rect_equipment_carousel">
          <div className="col-12 ">
            <h5 className="fw-bold text-primary rect_info_title">
              Besoin d'un service supplémentaire ?
            </h5>
            <Carousel
              data={equipmentClient}
              showModalService={getDataAndShowModalServices}
              loading={equipmentClientIsInitialLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
