import React, { useState, useEffect, useContext } from 'react';

import config from 'config/payment.config';
import { Link, useLocation } from 'react-router-dom';

import { Notification } from '@molecules';

import Carousel from '@organisms/Carousel/Carousel';

import CardRdv from '@components/Card_rdv/mini_card_rdv';
import Feature from '@components/Feature/Feature';
import Loader from '@components/Loader/Loader';
import Modal from '@components/Modal/ModalDefault';
import NotificationBanner from '@components/NotificationBanner/NotificationBanner';

import { NewDocumentContext } from '@context/NewDocumentContext';
import { NewJiraContext } from '@context/NewJiraContext';
import { ProjectContext } from '@context/ProjectContext';
import { UserContext } from '@context/UserContext';

import {
  useSkynetMainResponsableList,
  useSkynetProjectClient,
  useJiraProjectClient3Result,
  useSkynetNextRdv,
  useFaqMostViewed,
} from '@hooks/useApi';

import { ReactComponent as EnCours } from '@img/icons/Encours.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import { ReactComponent as Start } from '@img/icons/startDashboard.svg';
import { ReactComponent as Valid } from '@img/icons/valid_green.svg';
import { ReactComponent as NoRDV } from '@img/rdv.svg';

import ModalCancel from '../appointment/modalCancelAppointment/modalCancelAppointment';
import ModalInfoRdv from '../appointment/modalInfoAppointment/modalInfoAppointment';
import ModalPlace from '../appointment/modalPlaceAppointment/modalPlaceAppointment';

import DashboardSkeleton from './DashboardSkeleton';
import './dashboard.scss';
import Interlocateurs from './interlocuteurs/interlocuteurs';
import ModalContact from './interlocuteurs/modalContact';
import Requests from './requests/requests';
import './sliderNotifications.scss';
import Wallet from './wallet/wallet';
import Webinar from './webinar/webinar';

export default function Dashboard() {
  const [etapes, setEtapes] = useState(null);
  const [completeds, setCompleteds] = useState(null);
  const [showModalContact, setShowModalContact] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalInfoRdv, setShowModalInfoRdv] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [typeMail, setTypeMail] = useState('');
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalPlace, setShowModalPlace] = useState(false);
  const [dataModalPlace, setDataModalPlace] = useState(null);

  const location = useLocation();
  const { paymentResponseData } = location.state || {};
  const notificationConfig = config(paymentResponseData);

  const { user } = useContext(UserContext);
  const { hasDocumentToSign } = useContext(NewDocumentContext);
  const { hasProject } = useContext(ProjectContext);
  const { setHasNewJira } = useContext(NewJiraContext);

  const { data: dataProjectClient, isLoading: isLoadingProjectClient } =
    useSkynetProjectClient();
  const { data: dataMainResponsable, isLoading: isLoadingMainResponsable } =
    useSkynetMainResponsableList();
  const { data: dataFrequentQuestion, isLoading: isLoadingFrequentQuestion } =
    useFaqMostViewed(user ? user.platform : 'null');
  const { data: dataNextRdv, isLoading: isLoadingNextRdv } = useSkynetNextRdv();
  const { data: dataJiraProjectClient, isLoading: isLoadingJiraProjectClient } =
    useJiraProjectClient3Result();

  const isLoadingData =
    isLoadingProjectClient ||
    isLoadingMainResponsable ||
    isLoadingFrequentQuestion ||
    isLoadingNextRdv ||
    isLoadingJiraProjectClient;

  useEffect(() => {
    const project =
      dataProjectClient && dataProjectClient.length
        ? dataProjectClient[0]
        : null;

    if (project) {
      let etapesTab = [];
      Object.entries(JSON.parse(project.etapes)).map((etape) => {
        return etapesTab.push({ id: etape[0], ...etape[1] });
      });

      let completedsTab = etapesTab.filter((etape) => etape.completed === true);

      setCompleteds(completedsTab);
      setEtapes(etapesTab);
    }
  }, [dataProjectClient]);

  useEffect(() => {
    if (dataJiraProjectClient) {
      const nbNew = dataJiraProjectClient.issues.filter(
        (item) => item.new === true
      ).length;

      setHasNewJira(nbNew > 0);
    }
  }, [dataJiraProjectClient, setHasNewJira]);

  const etapeActuelle = () => {
    switch (completeds.length + 1) {
      case 1:
        return 'AFFECTATION';
      case 2:
        return 'PREPARATION';
      case 3:
        return 'ATELIER DE CADRAGE';
      case 4:
        return 'DEPLOIEMENT';
      case 5:
        return 'LANCEMENT';
      default:
        return false;
    }
  };

  const getStatus = () => {
    if (completeds.length === 0) {
      return (
        <div className="status start">
          <Start />
          Démarrage
        </div>
      );
    } else if (completeds.length > 0 && completeds.length < 4) {
      return (
        <div className="status encours">
          <EnCours />
          En cours
        </div>
      );
    } else if (completeds.length === 4 || completeds.length === 5) {
      return (
        <div className="status lance">
          <Valid />
          Lancé
        </div>
      );
    }
  };

  const onClickCancel = () => {
    setShowModalCancel(true);
    setShowModalInfoRdv(false);
  };

  if (isLoadingData) return <DashboardSkeleton />;

  return (
    <div className="w-100 dashboard">
      {notificationConfig && (
        <Notification
          type={paymentResponseData.type}
          hasCloseButton={true}
          className="mb-3 shadow-ec"
          data-cy="notification_payment"
          {...notificationConfig}
        />
      )}

      <Carousel
        className="slider-notifications mb-4"
        autoPlay={true}
        arrowsProps={{
          color: '#96A3BA',
        }}
        data-cy="carousel-notifications"
        items={[
          hasDocumentToSign && (
            <NotificationBanner
              data-cy="notification-document-to-sign"
              title="Mes documents"
              content="Vous avez des documents en attente de signature."
              link="/documents-to-sign"
              linkText="Signer les documents"
              color="start-color"
            />
          ),
          <NotificationBanner
            data-cy="notification-to-go-userClub"
            badge="Nouveau !"
            title="Inscrivez-vous à notre Club Utilisateurs et participez activement à l'évolution de nos logiciels !"
            link="https://clubutilisateurs.solutionscse.edenred.fr/com/login?back_url=%2Fcom%2Fhomepage"
            targetBlank={true}
            linkText="Je m'inscris"
            color="red-edenred"
          />,
        ]}
      />

      <div className="row me-md-0">
        {user?.meyClubId && (
          <div
            className="widget rect_info rect_info_wallet wallet col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3"
            data-cy="widget-wallet"
          >
            <div className="content">
              <Wallet />
            </div>
          </div>
        )}

        <Feature name="RENDEZ_VOUS">
          {dataNextRdv ? (
            <div
              className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv"
              data-cy="widget-rdv"
            >
              <div className="col-12">
                <h5 className="fw-bold text-primary rect_info_title">
                  Rendez-vous
                </h5>
                <div className="content">
                  <>
                    {dataNextRdv.total !== 0 && dataNextRdv.actions[0] ? (
                      <CardRdv
                        data={dataNextRdv.actions[0]}
                        hasDashboard={true}
                        hasButton={false}
                      />
                    ) : (
                      <div className="no-rdv">
                        <NoRDV />
                        <span>
                          Vous n'avez pas de rendez-vous prévus prochainement.
                          Et si c'était l'occasion d'échanger avec votre
                          chargé(e) de clientèle ?
                        </span>
                      </div>
                    )}
                  </>
                </div>
                {dataNextRdv.total !== 0 && dataNextRdv.actions[0] ? (
                  <button
                    className="btn btn-primary rounded-pill btn-block"
                    onClick={() => setShowModalInfoRdv(true)}
                  >
                    Voir mon rendez-vous
                  </button>
                ) : (
                  <Link
                    to={{ pathname: '/appointment/create' }}
                    className="btn btn-primary rounded-pill btn-block"
                  >
                    Prendre rendez-vous
                  </Link>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </Feature>
        {hasProject && dataProjectClient ? (
          <div
            className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 etat"
            data-cy="widget-project"
          >
            <div className="col-12">
              <h5 className="fw-bold text-primary rect_info_title">
                Etat de mon projet
              </h5>
              <div className="content">
                <>
                  {completeds && getStatus()}
                  <div className="steps">
                    {etapes &&
                      etapes.map((etape, index) => {
                        return (
                          <div
                            key={index}
                            className={`step ${
                              etape.completed
                                ? 'isActive'
                                : index === completeds.length
                                ? 'isCurrent'
                                : ''
                            }`}
                          ></div>
                        );
                      })}
                  </div>
                  <div className="now">
                    <p className="title">ETAPE ACTUELLE</p>
                    <p>{completeds && etapeActuelle()}</p>
                  </div>
                </>
              </div>
              <Link
                to={{ pathname: `/project` }}
                className="btn btn-primary rounded-pill btn-block"
              >
                Voir mon projet
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}

        {dataProjectClient && dataMainResponsable ? (
          <div
            className="widget rect_info rect_info_interlocateurs interlocuteur col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3"
            data-cy="widget-interlocutors"
          >
            <Interlocateurs
              infoProject={dataProjectClient[0]}
              infoResponsable={dataMainResponsable}
              hasProject={hasProject}
              setTitleModal={setTitleModal}
              setTypeMail={setTypeMail}
              setShowModalContact={setShowModalContact}
            />
          </div>
        ) : (
          ''
        )}

        {dataFrequentQuestion === null || dataFrequentQuestion?.length ? (
          <div
            className={`widget rect_info col-xxl-4 mb-2 mb-md-3 question col-12 col-sm-6 `}
            data-cy="widget-faq"
          >
            <div className="col-12">
              <h5 className="fw-bold text-primary rect_info_title">
                Questions fréquentes
              </h5>
              <div className="content">
                {dataFrequentQuestion ? (
                  dataFrequentQuestion.map((questionItem, index) => {
                    return (
                      <Link
                        key={index}
                        to={{ pathname: `${questionItem.path}` }}
                      >
                        <Question />
                        {questionItem.name}
                      </Link>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                )}
              </div>
              <Link
                to={{ pathname: `/help` }}
                className="btn btn-primary rounded-pill btn-block"
              >
                Voir toute la FAQ
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}

        {user.platformLink && (
          <div
            className="widget rect_info rect_info_webianr webinar col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3"
            data-cy="widget-webinar"
          >
            <div className="content">
              <Webinar user={user} />
            </div>
          </div>
        )}

        {dataJiraProjectClient === null ||
        dataJiraProjectClient?.issues?.length ? (
          <Feature name="DEMANDE_CLIENT">
            <div
              className={`widget rect_info col-xxl-4 mb-2 mb-md-3 demandes col-12 col-sm-6 `}
              data-cy="widget-requests"
            >
              <Requests
                data={dataJiraProjectClient.issues}
                nbRequests={dataJiraProjectClient.any}
              />
            </div>
          </Feature>
        ) : (
          ''
        )}

        <ModalInfoRdv
          showModal={showModalInfoRdv}
          setShowModal={setShowModalInfoRdv}
          setShowModalPlace={setShowModalPlace}
          onClickCancel={onClickCancel}
          setDataModalPlace={setDataModalPlace}
          data={dataNextRdv && dataNextRdv.actions[0]}
        />

        <ModalCancel
          showModal={showModalCancel}
          setShowModal={setShowModalCancel}
          data={dataNextRdv && dataNextRdv.actions[0]}
        />

        <ModalPlace
          showModal={showModalPlace}
          setShowModal={setShowModalPlace}
          setShowModalPlace={setShowModalPlace}
          data={dataModalPlace}
        />

        <ModalContact
          titleModal={titleModal}
          typeMail={typeMail}
          showModalContact={showModalContact}
          setShowModalSuccess={setShowModalSuccess}
          setShowModalContact={setShowModalContact}
        />

        <Modal
          show={showModalSuccess}
          handleClose={() => {
            setShowModalSuccess(false);
          }}
        >
          <div className="">
            <h2 className="fw-bold text-primary">
              Votre message a bien été envoyé !
            </h2>
            <h6 className="text-primary">
              Nous allons prendre en compte votre message et nous vous
              recontacterons dans les plus brefs délais.
            </h6>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary rounded-pill fw-bold"
                onClick={() => setShowModalSuccess(false)}
              >
                Fermer
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
