import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { apiCall, apiCallWithToken, formDataRequest } from '../helpers';

/***************************************/
/*************  GET  *******************/
/***************************************/

export const useJiraProjectClient = (options = {}) => {
  return useQuery({
    queryKey: ['jira-project-client'],
    queryFn: () =>
      apiCallWithToken(`/api/jira/project/client`).then((res) => res.data),
    ...options,
  });
};

export const useJiraProjectClient3Result = (options = {}) => {
  return useQuery({
    queryKey: ['jira-project-client-3-result'],
    queryFn: () =>
      apiCallWithToken(
        `/api/jira/project/client?maxResult=3&readOnly=true`
      ).then((res) => res.data),
    ...options,
  });
};

export const useSkynetContact = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-contact'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/contact`).then((res) => res.data),
    ...options,
  });
};

export const useSkynetDiscount = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-discount'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/discount`).then((res) => res.data),
    ...options,
  });
};

export const useSkynetContactFunction = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-contact-function'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/contact/function`).then((res) =>
        JSON.parse(res.data)
      ),
    ...options,
  });
};

export const useSkynetMainResponsable = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-main-responsable'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/main-responsable`).then((res) => res.data),
    ...options,
  });
};

export const useSkynetMainResponsableList = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-main-responsable-list'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/main-responsable-list`).then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useSkynetNextRdv = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-next-rdv'],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/list?typeAction=1&sousTypeActionCommun=RDV&maxResults=1&order=asc&dateFrom=${encodeURIComponent(
          dayjs().format()
        )}`
      ).then((res) => res.data),
    ...options,
  });
};

export const useSkynetProjectClient = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-project-client'],
    queryFn: () =>
      apiCallWithToken(`/api/project/list`).then((res) => res.data),
    ...options,
  });
};

export const useToggleFeature = (options = {}) => {
  return useQuery({
    queryKey: ['toggle-feature'],
    queryFn: () =>
      apiCall(`/api/config/toggle-feature/`).then((res) => {
        let toggleFeatureTab = {};
        res.data.forEach((item) => {
          toggleFeatureTab[item.ident] = item.enable;
        });

        return toggleFeatureTab;
      }),
    ...options,
  });
};

export const useSkynetCalendarBusySlot = (
  format,
  motif,
  selectedMonth,
  isCurrentMonth,
  options = {}
) => {
  return useQuery({
    queryKey: [
      format === 0 ? 'data-distance' : 'data-presentiel',
      selectedMonth,
      String(format),
      String(format !== 0 ? 30 : parseInt(motif?.duration)),
    ],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/calendar-busy-slot?interval=${
          format !== 0 ? 30 : parseInt(motif.duration)
        }${
          !isCurrentMonth(selectedMonth)
            ? `&month=${dayjs(selectedMonth).format('YYYY-MM')}`
            : ''
        }`,
        'GET'
      ).then((res) => res.data),
    ...options,
  });
};

export const useSkynetActionsList = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-actions-list'],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/list?typeAction=1&sousTypeActionCommun=RDV&order=asc&dateFrom=${encodeURIComponent(
          dayjs().format()
        )}`
      ),
    ...options,
  });
};

export const useSkynetActionsList1FromDate = (dataAction, options = {}) => {
  return useQuery({
    queryKey: ['skynet-actions-list-1-from-date', dataAction?.dateDebut],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/list?typeAction=1&sousTypeActionCommun=RDV&dateFrom=${encodeURIComponent(
          dataAction?.dateDebut
        )}&maxResults=1&order=asc`
      ).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetActionsList20 = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-actions-list-20'],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/list?typeAction=1&sousTypeActionCommun=RDV&dateTo=${encodeURIComponent(
          dayjs().format()
        )}&maxResults=20&order=asc`
      ),
    ...options,
  });
};
export const useSkynetCancelSubmotifList = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-cancel-submotif-list'],
    queryFn: () =>
      apiCallWithToken('/api/skynet/actions/cancel-submotif/list', 'GET').then(
        (res) => {
          return res.data;
        }
      ),
    ...options,
  });
};

export const useSkynetReviewCriterias = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-review-criterias'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/review_criterias`).then((res) => res.data),
    ...options,
  });
};

export const useMotifs = (options = {}) => {
  return useQuery({
    queryKey: ['motifs'],
    queryFn: () =>
      apiCallWithToken(`/api/available_subject_actions`).then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useSkynetEDFAClient = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-edfa-client'],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/edfa/client`).then((res) =>
        JSON.parse(res.data)
      ),
    ...options,
  });
};

export const useSkynetDocumentCompleted = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-document-completed'],
    queryFn: () =>
      apiCallWithToken('/api/skynet/documents?status=completed').then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useListCertificationSubsidies = (options = {}) => {
  return useQuery({
    queryKey: ['list-certification-subsidies'],
    queryFn: () =>
      apiCallWithToken('/api/document/list_certification_subsidies').then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useSkynetEquipmentClient = (options = {}) => {
  return useQuery({
    queryKey: ['skynet-equipment-client'],
    queryFn: () =>
      apiCallWithToken('/api/skynet/equipment/flat/client').then((res) =>
        JSON.parse(res.data)
      ),
    ...options,
  });
};

export const useSkynetAction = (rdvId, options = {}) => {
  return useQuery({
    queryKey: ['skynet-action', String(rdvId)],
    queryFn: () =>
      apiCallWithToken(`/api/skynet/action/${rdvId}`, 'GET').then((res) => {
        if (res.data?.length !== 0) {
          let utilisateursAssignesCat =
            res.data.utilisateurAssignes[0].lastname +
            ' ' +
            res.data.utilisateurAssignes[0].firstname;

          let dataCopy = res.data;
          dataCopy.utilisateursAssignes = utilisateursAssignesCat;

          const dateEcheance = dayjs(res.data.dateEcheance);
          dataCopy.motif = {
            duration: dateEcheance.diff(res.data.dateDebut, 'minute'),
          };

          return dataCopy;
        }
      }),
    ...options,
  });
};

export const useGandiCheck = (domainName, options = {}) => {
  return useQuery({
    queryKey: ['gandi-check', domainName],
    queryFn: () =>
      apiCallWithToken(`/api/project/domain-availability/${domainName}`).then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useFaqTheme = (plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-theme', plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_themes?${
          plateform ? '&faqSubThemes.faqPages.platforms.name=' + plateform : ''
        }`
      ).then((res) => {
        const datas = res.data['hydra:member'];
        let tab = [];
        datas.forEach((data) => {
          tab.push({
            name: data.title,
            path: `/help/${data.id}`,
            disabled: !data.enabled,
          });
        });

        return tab;
      }),
    ...options,
  });
};

export const useFaqMostVoted = (plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-most-voted', plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_pages?order[vote]=desc&page=1&platforms.name=${plateform}`
      ).then((res) => {
        let questionVoteTab = [];
        res.data['hydra:member'].forEach((question) => {
          if (question.enabled && questionVoteTab.length < 3) {
            questionVoteTab.push({
              name: question.title,
              path: `/help/question/${question.id}`,
            });
          }
        });
        return questionVoteTab;
      }),
    ...options,
  });
};

export const useFaqMostViewed = (plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-most-viewed', plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_pages?order[numberView]=desc&page=1&platforms.name=${plateform}`
      ).then((res) => {
        let questionTab = [];
        res.data['hydra:member'].forEach((question) => {
          if (question.enabled && questionTab.length < 3) {
            questionTab.push({
              name: question.title,
              path: `/help/question/${question.id}`,
            });
          }
        });
        return questionTab;
      }),
    ...options,
  });
};

export const useFaqPined = (plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-pined', plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_pages?pined=true&page=1&platforms.name=${plateform}`
      ).then((res) => {
        if (res.data['hydra:totalItems'] > 0) {
          return {
            name: res.data['hydra:member'][0].title,
            path: `/help/question/${res.data['hydra:member'][0].id}`,
          };
        }

        return null;
      }),
    ...options,
  });
};

export const useFaqSubCategoryThemes = (
  subThematic,
  plateform,
  options = {}
) => {
  return useQuery({
    queryKey: ['faq-subcategory-themes', subThematic, plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_pages?faqSubTheme.id=${subThematic}&platforms.name=${plateform}`
      ).then((res) => {
        const datas = res.data['hydra:member'];

        if (datas.length === 0) {
          return {
            title: '',
            thematics: [],
            sousThemeName: '',
          };
        }

        let tab = [];
        datas.forEach((data) => {
          tab.push({
            id: data.id,
            title: data.title,
            disabled: !data.enabled,
            content: data.content,
            image: false,
            video: false,
          });
        });

        return {
          title: res.data['hydra:member'][0].faqSubTheme.faqTheme.title,
          thematics: tab,
          sousThemeName: res.data['hydra:member'][0].faqSubTheme.title,
        };
      }),
    ...options,
  });
};

export const useFaqCategoryThemes = (thematic, plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-category-themes', thematic, plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_sub_themes?faqTheme.id=${thematic}&faqPages.platforms.name=${plateform}`
      ).then((res) => {
        const datas = res.data['hydra:member'];
        let tab = [];
        datas.forEach((data) => {
          tab.push({
            name: data.title,
            path: `/help/${thematic}/${data.id}`,
            disabled: !data.enabled,
          });
        });

        return {
          title: res.data['hydra:member'][0].faqTheme.title,
          thematics: tab,
        };
      }),
    ...options,
  });
};

export const useFaqPagesPined = (plateform, options = {}) => {
  return useQuery({
    queryKey: ['faq-pages-pined', plateform],
    queryFn: () =>
      apiCallWithToken(
        `/api/faq_pages?pined=true&page=1&platforms.name=${plateform}`
      ).then((res) => {
        if (res.data['hydra:totalItems'] > 0) {
          return {
            name: res.data['hydra:member'][0].title,
            path: `/help/question/${res.data['hydra:member'][0].id}`,
          };
        }

        return null;
      }),
    ...options,
  });
};

/***************************************/
/*************  PUT  *******************/
/***************************************/

export const useFaqPagesPUT = (options = {}) => {
  return useMutation({
    mutationFn: ({ id, data }) =>
      apiCallWithToken(`/api/faq_pages/${id}`, 'PUT', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetPutActionCancel = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(
        `/api/skynet/actions/${data.id}/cancel`,
        'PUT',
        data
      ).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetPutAction = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(
        `/api/skynet/action/${data.rdvId}`,
        'PUT',
        data.action
      ).then((res) => {
        if (res.data?.length !== 0) {
          let dataRecap = data.dataActionList.actions[0];
          dataRecap.dateDebut = res.data.dateDebut;
          dataRecap.dateEcheance = res.data.dateEcheance;

          dataRecap.utilisateursAssignes = [];

          let utilisateursAssignesCat =
            res.data.utilisateurAssignes[0].lastname +
            ' ' +
            res.data.utilisateurAssignes[0].firstname;
          dataRecap.utilisateursAssignes[0] = utilisateursAssignesCat;

          if (dataRecap.sousTypeAction === 'RDV à distance') {
            dataRecap.format = '0';
          } else if (dataRecap.sousTypeAction === 'RDV demande de rappel') {
            dataRecap.format = '2';
          } else {
            dataRecap.format = '1';
          }

          return dataRecap;
        }
      }),
    ...options,
  });
};

export const useSkynetContactPut = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(`/api/skynet/contact`, 'PUT', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetAddressMeetingPut = (options = {}) => {
  return useMutation({
    mutationFn: ({ data, id }) =>
      apiCallWithToken(`/api/skynet/address/${id}`, 'PUT', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetClientPut = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(`/api/skynet/client`, 'PUT', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

/***************************************/
/*************  POST  ******************/
/***************************************/

export const useEmailSupportPost = (options = {}) => {
  return useMutation({
    mutationFn: ({ data }) =>
      apiCallWithToken(`/api/skynet/email/support`, 'POST', data).then(
        (res) => {
          return res.data;
        }
      ),
    ...options,
  });
};

export const useSkynetActionPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(`/api/skynet/action`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSkynetEmailPost = (options = {}) => {
  return useMutation({
    mutationFn: ({ typeMail, data }) =>
      apiCallWithToken(`/api/skynet/email/${typeMail}`, 'POST', data),
    ...options,
  });
};

export const useEdenredIdentityPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(
        `/api/edenred/identity/change-password`,
        'POST',
        data
      ).then((res) => res.data),
    ...options,
  });
};

export const useSkynetAddressPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(`/api/skynet/address`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useResiliationValidationPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCall(`/resiliation/valide`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useResiliationPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCall(`/resiliation/send-confirmation`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useSubmitResiliationPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCall(`/resiliation/submit-resiliation`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useResendConfirmationResiliationPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCall(`/resiliation/resend-confirmation`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const usePaymentPost = (options = {}) => {
  return useMutation({
    mutationFn: (data) =>
      apiCallWithToken(`/api/payment`, 'POST', data).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

/***************************************/
/***********  PREFETCH  ****************/
/***************************************/

export const usePrefetchSkynetCalendarBusySlotDistance = (
  format,
  motif,
  options = {}
) => {
  const client = useQueryClient();

  if (!motif) return;

  return client.prefetchQuery({
    queryKey: [
      'data-distance',
      dayjs().format('MM/01/YYYY'),
      '0',
      String(motif.duration),
    ],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/calendar-busy-slot?interval=${
          format !== 0 ? 30 : parseInt(motif.duration)
        }`,
        'GET'
      ).then((res) => res.data),
    config: {
      refetchOnWindowFocus: false,
    },
    ...options,
  });
};

export const usePrefetchSkynetCalendarBusySlotPresentiel = (options = {}) => {
  const client = useQueryClient();

  return client.prefetchQuery({
    queryKey: ['data-presentiel', dayjs().format('MM/01/YYYY'), '2', '30'],
    queryFn: () =>
      apiCallWithToken(
        `/api/skynet/actions/calendar-busy-slot?interval=30`,
        'GET'
      ).then((res) => res.data),
    config: {
      refetchOnWindowFocus: false,
    },
    ...options,
  });
};

export const usePrefetchMainResponsable = (options = {}) => {
  const client = useQueryClient();

  return client.prefetchQuery({
    queryKey: ['skynet-main-responsable'],
    queryFn: () =>
      apiCallWithToken('/api/skynet/main-responsable', 'GET').then((res) =>
        JSON.parse(res.data)
      ),
    config: {
      refetchOnWindowFocus: false,
    },
    ...options,
  });
};

export const useDomainNameReservationRequest = (options = {}) => {
  return useMutation({
    mutationFn: (formData) =>
      formDataRequest(`/api/project/step/preparation`, 'POST', formData),
    ...options,
  });
};
// EQUIPMENT APIs

export const useSubsidyWalletAmountRequest = (options = {}) => {
  return useQuery({
    queryKey: ['equipment-subsidy_wallet_amount'],
    queryFn: () =>
      apiCallWithToken(`/api/meyclub/subsidy-wallet-amount`, 'GET').then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useGiftVoucherBalanceRequest = (options = {}) => {
  return useQuery({
    queryKey: ['equipment-gift_voucher_balance'],
    queryFn: () =>
      apiCallWithToken(`/api/equipment/gift_voucher_balance`, 'GET').then(
        (res) => res.data
      ),
    ...options,
  });
};

export const useContractRenewalDate = (options = {}) => {
  return useQuery({
    queryKey: ['equipment-contract_renewal_date'],
    queryFn: () =>
      apiCallWithToken(`/api/equipment/contract_renewal_date`, 'GET').then(
        (res) => res.data
      ),
    ...options,
  });
};
