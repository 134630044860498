import React, { useState } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { TextField } from '@molecules';

import Table from '@components/Table/TableDefault';

import { ReactComponent as Delete } from '@img/icons/delete.svg';
import { ReactComponent as Search } from '@img/icons/search.svg';

export default function CertificationSubsidies({
  loading,
  error,
  data,
  setLoadingDownload,
}) {
  const [searchBarInput, setSearchBarInput] = useState('');
  const [datatableState, setDatatableState] = useState(null); // eslint-disable-line no-unused-vars

  const pdfHandler = (rowData, downloadable = false) => {
    const id = rowData.uri.substring(rowData.uri.lastIndexOf('/') + 1);

    if (downloadable) {
      setLoadingDownload(true);
      axios
        .get(
          `${process.env.REACT_APP_BACK_URL}/api/document/view_certification_subsidy/${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        )
        .then((res) => {
          const date = dayjs(rowData.date).format('DDMMYYYY');
          const fileName = `Attestation_de_depot_${
            rowData.amount / 100
          }_euro-${date}.pdf`;

          const linkSource = `${res.data.data}`;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoadingDownload(false);
        })
        .catch((error) => {
          setLoadingDownload(false);
        });
    } else {
      let win = window.open(`/pdfViewer/${id}?isSubsidy=true`, '_blank');
      win.focus();
    }
  };

  const columns = [
    {
      title: 'Libellé',
      data: null,
      className: 'dt-left libelle',
      orderable: false,
      render: function (data, type, row) {
        const date = dayjs(row.date).format('DD/MM/YYYY');
        const amount = row.amount / 100;

        return `Attestation de dépot - ${amount.toLocaleString()} € - ${date}`;
      },
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);
      },
    },
    {
      title: 'Date',
      data: 'date',
      className: 'dt-date dt-right',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(row.date).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-none">' + data.date + '</span>' + date
          : '';
      },
    },
    {
      title: 'Action',
      data: null,
      orderable: false,
      className: 'dt-right',
      width: '210px',
      createdCell: (td, cellData, rowData, row, col) =>
        createRoot(td).render(
          <div className="d-flex justify-content-end bill-actions">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => pdfHandler(rowData)}
            >
              Consulter
            </button>
            <button
              type="button"
              className="ms-2 btn btn-primary"
              onClick={() => pdfHandler(rowData, true)}
            >
              Télécharger
            </button>
          </div>
        ),
    },
  ];

  const onChangeSearchBar = (e) => {
    e.preventDefault();
    setSearchBarInput(e.target.value);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 rect_info rect_info_search">
          {loading ? (
            <div className="col-12 skeleton">
              <SkeletonTheme width="100%">
                <section className="d-flex flex-column align-items-center justify-center w-100">
                  <Skeleton height={20} width="30%" className="mb-4" />
                  <Skeleton
                    height={20}
                    width="100%"
                    className="mb-0 search-bar"
                  />
                </section>
              </SkeletonTheme>
            </div>
          ) : (
            <div className="row mx-0 d-flex search-bar-container flex-column flex-md-row">
              <div className="col-12 col-md-6">
                <h5 className="rect_info_title">Rechercher par nom</h5>
                <form className="form-inline form-search d-flex flex-nowrap">
                  <TextField
                    className="form-control-sm me-3 w-100 border-0"
                    placeholder={`Ex: Attestation ${dayjs().format(
                      'DD/MM/YYYY'
                    )}`}
                    onChange={(e) => onChangeSearchBar(e)}
                    aria-label="Search"
                    value={searchBarInput}
                    data-cy="certification-subsidies-search"
                  />
                  <Delete
                    className={`delete-icon ${
                      searchBarInput !== '' ? 'active' : ''
                    }`}
                    onClick={() => setSearchBarInput('')}
                  />
                  <Search className="search-icon" />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 rect_info rect_info_document">
          <div className="col-12">
            {loading ? (
              <div className="col-12 skeleton">
                <SkeletonTheme width="100%">
                  <section className="d-flex flex-column align-items-center justify-center w-100">
                    <div className="text-end w-100">
                      <Skeleton
                        height={30}
                        width={300}
                        className="mb-4 rounded-pill button"
                      />
                    </div>
                    <Skeleton height={60} className="mb-2" />
                    <Skeleton
                      height={34}
                      count={10}
                      className="mb-1 line-table"
                    />
                  </section>
                </SkeletonTheme>
              </div>
            ) : error ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : data.length > 0 ? (
              <div className="">
                <Table
                  name="certificationSubsidies"
                  data={data}
                  columns={columns}
                  searchBarInput={searchBarInput}
                  setDatatable={setDatatableState}
                  dataCy="certification-subsidies-table"
                />
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous avez une question sur vos documents ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </div>
            ) : (
              <h5 className="fw-bold text-primary">
                Vous n'avez aucun document à disposition
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
