import React from 'react';

import Carousel from 'stories/organisms/Carousel/Carousel';
import 'swiper/css/bundle';

import { ReactComponent as Conseil } from '@img/icons/Conseil.svg';
import { ReactComponent as AccompagnementComm } from '@img/icons/accomp_com.svg';
import { ReactComponent as AccompagnementGestion } from '@img/icons/accompagnement_gestion.svg';
import { ReactComponent as Formation } from '@img/icons/formation.svg';

import CarouselItem from './CarouselItem';
import './carousel.scss';

export default function CarouselComponent({ data, showModalService, loading }) {
  const breakpoints = {
    1450: {
      slidesPerView: 3.5,
    },
    1350: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 2.5,
    },
    1150: {
      slidesPerView: 2,
    },
    1100: {
      slidesPerView: 1.5,
    },
    992: {
      slidesPerView: 2.3,
    },
    767: {
      slidesPerView: 2.2,
    },
    550: {
      slidesPerView: 2,
    },
    500: {
      slidesPerView: 1.5,
    },
    250: {
      slidesPerView: 1.2,
    },
  };

  const items = [
    {
      image: <AccompagnementGestion />,
      title: 'Accompagnement en gestion',
      description:
        'Faites-vous aider par nos experts pour créer et paramétrer vos offres.',
      selected: data?.accompagnementGestion,
      dataCy: 'equipment-swipper-accompagnement-gestion',
    },
    {
      image: <AccompagnementComm />,
      title: 'Accompagnement en communication',
      description:
        'Faites-vous aider par nos experts pour créer et paramétrer vos offres.',
      selected: data?.accompagnementComm,
      dataCy: 'equipment-swipper-accompagnement-communication',
    },
    {
      image: <AccompagnementGestion />,
      image2: <AccompagnementComm />,
      title: 'Accompagnement gestion + communication',
      description:
        'Nos experts vous aident pour gérer vos offres et votre communication.',
      selected:
        data?.accompagnementGestion && data?.accompagnementComm ? true : false,
      dataCy: 'equipment-swipper-accompagnement-gestion-communication',
    },
    {
      image: <Formation />,
      title: 'Formation',
      description:
        'Nos experts vous aident pour valoriser votre mandat et animer votre side internet.',
      selected: false,
      dataCy: 'equipment-swipper-formation',
    },
    {
      image: <Conseil />,
      title: 'Conseil',
      description:
        'Nos experts vous aident pour valoriser votre mandat et animer votre side internet.',
      selected: false,
      dataCy: 'equipment-swipper-conseil',
    },
  ];

  return (
    <Carousel
      className="carousel-swiperjs-equipment"
      autoPlay={false}
      loop={false}
      hasPagination={false}
      slidesPerView={3.5}
      slidesPerGroup={1}
      spaceBetween={2}
      breakpoints={breakpoints}
      items={items.map((item, index) => (
        <CarouselItem
          key={index}
          image={item.image}
          image2={item.image2}
          title={item.title}
          dataCy={item.dataCy}
          description={item.description}
          selected={item.selected}
          showModalService={showModalService}
          loading={loading}
        />
      ))}
    />
  );
}
