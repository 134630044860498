import React, { useContext } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

import { UserContext } from '@context/UserContext';

import GoogleTagManagement from '@GoogleTagManagement';

import { useFaqCategoryThemes, useFaqPagesPined } from '@hooks/useApi';

import './faq.scss';

export default function FaqCategory() {
  const { thematic } = useParams();
  const { user } = useContext(UserContext);

  const {
    data: faqThemeData,
    isLoading: faqThemeDataLoading,
    isError: faqThemeDataError,
  } = useFaqCategoryThemes(thematic, user ? user.platform : 'null', {
    enabled: !!user,
  });

  const {
    data: questionPinedData,
    isLoading: questionPinedLoading,
    isError: questionPinedError,
  } = useFaqPagesPined(user ? user.platform : 'null', {
    enabled: !!user,
  });

  const isLoadingData = faqThemeDataLoading || questionPinedLoading;
  const isErrorData = faqThemeDataError || questionPinedError;

  return (
    <div className="w-100 faq">
      <GoogleTagManagement titlePage={faqThemeData?.title} />
      <div className="header-faq align-items-center">
        <h3 className="title_page">Besoin d'aide</h3>
        <Link
          to={{ pathname: '/help/contact' }}
          className="btn btn-primary rounded-pill contact"
        >
          Contactez-nous
        </Link>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12">
            {isErrorData ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : (
              <>
                {questionPinedData && (
                  <div className="featured_item">
                    <p>{questionPinedData.name}</p>
                    <Link
                      to={{ pathname: `${questionPinedData.path}` }}
                      className="btn btn-primary rounded-pill read_feature"
                    >
                      Lire l'article
                    </Link>
                  </div>
                )}
                {isLoadingData ? (
                  <Skeleton height={20} width={200} className="mb-4" />
                ) : (
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={{ pathname: `/help` }}>Besoin d'aide</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {faqThemeData?.title}
                      </li>
                    </ol>
                  </nav>
                )}

                {!isLoadingData && (
                  <Link
                    to={{ pathname: `/help` }}
                    className="return text-primary text-decoration-none"
                  >
                    <i className="fa fa-arrow-left me-2"></i>Retour
                  </Link>
                )}
                <div className="thematic">
                  <h5 className="fw-bold text-primary rect_info_title">
                    {isLoadingData ? (
                      <Skeleton height={20} width={100} className="" />
                    ) : (
                      faqThemeData?.title
                    )}
                  </h5>
                  {isLoadingData ? (
                    <div className="skeleton">
                      <SkeletonTheme width="100%">
                        <section className="d-flex justify-content-start w-100 flex-wrap thematic-skeleton">
                          {[...Array(7)].map((e, i) => {
                            return (
                              <Skeleton height={118} width="100%" key={i} />
                            );
                          })}
                        </section>
                      </SkeletonTheme>
                    </div>
                  ) : (
                    <div className="thematic-items">
                      {faqThemeData?.thematics.map((thematic, index) => {
                        return (
                          <Link
                            key={index}
                            to={{ pathname: `${thematic.path}` }}
                            className="thematic-item"
                          >
                            {thematic.name}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous ne trouvez pas de réponse à vos questions ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
