import React, { forwardRef } from 'react';

import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { Button, Chip, Radio } from '@atoms';

import { LoadingButton } from '@molecules';

import Modal from '@components/Modal/ModalDefault';

import { useSkynetDiscount, usePaymentPost } from '@hooks/useApi';

import { formatPrice } from '@helpers';

import './ModalcreditChequeCadeau.scss';

const ModalCreditChequeCadeau = forwardRef(
  ({ showModal, setShowModal }, ref) => {
    const { register, handleSubmit, watch, reset } = useForm({
      defaultValues: {
        amount: '',
        'payment-method': 'on',
      },
    });

    //TODO: Add loader :)
    const { mutate: mutatePaymentPost, isLoading: isPaymentLoading } =
      usePaymentPost({
        onSettled: (data) => {
          setShowModal(false);
          reset();
          window.location.href = data.url;
        },
      });

    const onSubmit = (data) => {
      let formData = new FormData();
      formData.append('amount', parseFloat(data.amount));

      mutatePaymentPost(formData);
    };

    const {
      data: skynetDiscountData,
      isFetched: isFetchedDiscount,
      isLoading: isLoadingDiscount,
    } = useSkynetDiscount({
      enabled: !!showModal,
    });

    const discountAmount = skynetDiscountData?.amount;

    const amountWatch = watch('amount');

    const discountedPrice = (amountWatch * (100 - discountAmount)) / 100;

    return (
      <Modal
        data-cy="modal-credit-cc"
        size="lg"
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        className="modal_crediter_cheque_cadeau"
        title={
          <h1 className="fw-bold text-primary text-center w-100 fs-6 mb-0">
            Créditer votre porte-monnaie
          </h1>
        }
        titleClassName="w-100"
        ref={ref}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="fw-bold text-primary fs-7 mb-0">
              Indiquez le montant que vous souhaitez créditer sur votre
              porte-monnaie
            </div>
            <div>
              <div className=" fs-7 mb-1">
                Ce montant s'ajoutera à votre solde actuel
              </div>
              <div className="d-flex gap-2 align-items-center flex-column flex-md-row">
                <div className="input">
                  <i className="input-icon">€</i>
                  <input
                    data-cy="input_amount_cc"
                    type="number"
                    placeholder="Ex: 50.00"
                    min="0.01"
                    step="0.01"
                    name="amount"
                    className={`no-focus ${
                      amountWatch ? 'border-success' : ''
                    }`}
                    {...register('amount', {
                      required: true,
                      validate: (value) =>
                        /^-?\d*(\.\d{0,2})?$/.test(value) ||
                        'Doit être un nombre à deux décimales',
                    })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                  />
                </div>

                {discountAmount && discountAmount > 0 ? (
                  <div data-cy="discount-chip" className="discount-amount fs-7">
                    <Chip
                      label={`Vos conditions commerciales : ${discountAmount} % de remise`}
                      margin="0px 0px 0px 0px"
                      id="chip-discount"
                      textColor="bleu_dodger"
                      textWeight="normal"
                      backgroundColorName="bleu-azur"
                      endIcon={
                        <i
                          className="fa fa-circle-info"
                          data-tip
                          data-for="discountInfo"
                        ></i>
                      }
                    />
                    <ReactTooltip
                      id="discountInfo"
                      place="top"
                      type="info"
                      effect="solid"
                      className="reactTooltipInformation"
                    >
                      <span>
                        Cette remise est valade jusqu'au{' '}
                        {skynetDiscountData?.endDate}
                      </span>
                    </ReactTooltip>
                  </div>
                ) : null}
              </div>
              {amountWatch &&
              amountWatch > 0 &&
              discountAmount &&
              discountAmount > 0 ? (
                <div className="discount-amount-description fw-bold fs-7 mt-2">
                  Grâce à ces conditions commerciales, le montant à régler est
                  de {formatPrice(discountedPrice)} € (le montant crédité sera
                  de {formatPrice(amountWatch)} €).
                </div>
              ) : null}
            </div>
            <div className="paiement-phase">
              <div className="fw-bold text-primary fs-7 mb-3 mt-3">
                Sélectionnez votre mode de paiement
              </div>

              <div className="amounts-entries pt-3 py-1">
                <div className="payement-method d-flex align-items-center mx-xs-3 pb-2">
                  <Radio
                    id="btn-radio-virement"
                    size="s"
                    name="payment-method"
                    {...register('payment-method')}
                  />
                  <div className="payement-method-title align-items-center fs-7 fw-bold text-primary ">
                    Par virement immediat
                  </div>
                  <Chip
                    label="Sans frais"
                    margin="0px 0px 0px 8px"
                    textColor="bleu_dodger"
                    textWeight="normal"
                    backgroundColorName="bleu-azur"
                  />
                </div>
                <div className="payement-method-description  mx-xs-5 fs-7">
                  <span>
                    Vous serez redirigé vers votre banque pour confirmer le
                    paiement, assurez-vous de respecter les règles de plafonds
                    appliquées par votre banque.
                  </span>
                  <ol>
                    <li>Séléctionnez votre banque</li>
                    <li>
                      Connectez-vous avec vos identifiants bancaire du CSE
                    </li>
                    <li>Validez la transaction</li>
                    <li>Votre paiement est confirmé</li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="buttons">
              <Button
                label="Annuler"
                style="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              />
              <LoadingButton
                type="submit"
                label="Créditer mon porte-monnaie"
                id="btn-crediter-cc"
                endIcon={<i className="fa fa-arrow-up-right-from-square"></i>}
                disabled={
                  !amountWatch || amountWatch <= 0 || !isFetchedDiscount
                }
                isLoading={isLoadingDiscount || isPaymentLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
);

export default ModalCreditChequeCadeau;
