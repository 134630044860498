import React from 'react';

import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import { Button } from '../../atoms';

import './LoadingButton.scss';

export const LoadingButton = ({
  className,
  isLoading = true,
  spinnerColor = '#00BFFF',
  spinnerType = 'Oval',
  spinnerHeight = 20,
  ...props
}) => {
  className = `loading-button-ec ${isLoading ? 'loading' : ''} ${className}`;

  const LoaderComponent = (
    <Loader
      type={spinnerType}
      color={spinnerColor}
      height={spinnerHeight}
      className={'loading-spinner'}
    />
  );

  return React.createElement(
    Button,
    {
      className,
      disabled: isLoading,
      ...props,
    },
    isLoading && LoaderComponent
  );
};

LoadingButton.propTypes = {
  className: PropTypes.string,
};

LoadingButton.defaultProps = {
  className: '',
};
