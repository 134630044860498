import './ArrowButton.scss';
import { ReactComponent as Arrow } from './arrow.svg';

const ArrowButton = ({
  direction,
  textFallback,
  color = '#162056',
  style,
  ...props
}) => {
  return (
    <Arrow
      alt={textFallback}
      className={`arrow arrow--${direction}`}
      style={{ '--color': color }}
      {...props}
    />
  );
};

export default ArrowButton;
