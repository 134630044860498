import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import RecapCardRDV from '@components/Card_rdv/recap_card_rdv';
import LoadingButton from '@components/LoadingButton/LoadingButton';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import GoogleTagManagement from '@GoogleTagManagement';

import { useSkynetMainResponsable, useSkynetActionPost } from '@hooks/useApi';

import { convertTimeToMs } from '@helpers';

import { ReactComponent as Man } from '@img/man_recap.svg';
import { ReactComponent as Women } from '@img/women_recap.svg';

import './recapitulatif.scss';

export default function Recapitulatif() {
  const { goToNextStep, motif, date } = useContext(CreateAppointmentContext);

  const queryClient = useQueryClient();

  const { data: dataMainResponsable } = useSkynetMainResponsable({
    cacheTime: convertTimeToMs(1, 'day'),
    staleTime: convertTimeToMs(1, 'day'),
  });

  const { mutate: createAppointmentMutation, isLoading } = useSkynetActionPost({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['skynet-actions-list'],
      });

      queryClient.invalidateQueries({
        queryKey: ['skynet-next-rdv'],
      });

      queryClient.invalidateQueries({
        queryKey: ['data-distance'],
      });

      queryClient.invalidateQueries({
        queryKey: ['data-presentiel'],
      });
    },
  });

  const handleCreateAppointment = async () => {
    const token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));

    await createAppointmentMutation({
      subjectId: motif.id,
      date,
      token,
    });
    goToNextStep();
    localStorage.removeItem('create-appointment-data');
  };

  return (
    <div className="step recapitulatif">
      <GoogleTagManagement titlePage="Récapitulatif du rendez-vous" />
      <div className="title text-primary fw-bold text-center">
        Récapitulatif de votre rendez-vous
      </div>
      <div className="content_recap d-flex justify-content-around align-items-end">
        <Man className="d-none d-xl-block" />
        <RecapCardRDV infoResponsable={dataMainResponsable} />
        <Women className="d-none d-xl-block" />
      </div>
      <div className="text-center button d-flex flex-column align-items-center">
        <span className="text-primary">Est-ce que tout est bon ?</span>
        <LoadingButton
          type="button"
          classes="btn btn-primary rounded-pill fw-600"
          id="sendlogin"
          text="Confirmer le rendez-vous"
          loading={isLoading}
          onClick={handleCreateAppointment}
          spinnerHeight="20"
          dataCy="confirm-button"
        />
      </div>
    </div>
  );
}
