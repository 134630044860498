import React, { forwardRef } from 'react';

import './radio.scss';

export const Radio = forwardRef(
  ({ label, name, isDisabled = false, ...props }, ref) => {
    const id = `radio-${name.replace(/\W/g, '-').toLowerCase()}`;

    const options = {
      id: id,
      'data-cy': id,
      name: name,
      ...props,
    };

    return (
      <div
        className={`custom-control custom-radio ${
          isDisabled ? 'disabled' : ''
        }`}
      >
        <input
          ref={ref}
          className="form-check-input custom-control-input"
          type="radio"
          disabled={isDisabled}
          {...options}
        />
        <label
          className="form-check-label custom-control-label cursor-pointer text-primary"
          htmlFor={options.id}
        >
          {label}
        </label>
      </div>
    );
  }
);
