import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Feature from '@components/Feature/Feature';

import { ReactComponent as User } from '@img/icons/user.svg';

export default function Interlocuteurs({
  infoResponsable,
  hasProject,
  setTitleModal,
  setTypeMail,
  setShowModalContact,
}) {
  const [numberSlide, setNumberSlide] = useState(0);

  const responsablesArray = Object.entries(infoResponsable).map(
    ([key, value]) => ({
      ...value,
      type: key,
    })
  );

  const getDataByTypeOfResponsable = (type) => {
    switch (type) {
      case '1':
        return {
          job: 'Chef de projet',
          badge: 'Gestion de votre projet',
          type: 'cdp',
          titleModal: 'Contacter votre chef de projet',
          acceptsAppointments: false,
          canBeContacted: true,
          displayed: hasProject,
        };
      case '3':
        return {
          job: 'Chargé de clientèle',
          badge: 'Vie de votre contrat',
          type: 'cdc',
          titleModal: 'Contacter votre chargé de clientèle',
          acceptsAppointments: true,
          canBeContacted: true,
          displayed: true,
        };
      case '22':
        return {
          job: 'Consultant ASC',
          badge: 'Gestion de vos ASC',
          type: 'asc',
          titleModal: 'Contacter votre consultant ASC',
          acceptsAppointments: false,
          canBeContacted: true,
          displayed: true,
        };

      default:
        return {
          job: 'Chargé de clientèle',
          badge: 'Contacter votre chargé de clientèle',
          type: 'cdc',
          titleModal: 'Contacter votre chargé de clientèle',
          acceptsAppointments: true,
          canBeContacted: true,
          displayed: true,
        };
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 667,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick',
      },
    ],
  };
  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.interlocuteurs').width();
      if (containerWidth !== 0) {
        $('.contact').outerWidth(containerWidth / 2 - 16);
        let itemLength = $('.contact').length;
        let itemWidth = $('.contact').outerWidth(true);
        let numberSlideCalc = containerWidth / itemWidth;
        setNumberSlide(
          numberSlideCalc > itemLength ? itemLength : numberSlideCalc
        );
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="col-12 interlocuteurs">
      <h5 className="fw-bold text-primary rect_info_title">
        Mes interlocuteurs
      </h5>
      <div className="h-100">
        <Slider {...settings} className={`slider slider-contacts contacts`}>
          {responsablesArray &&
            responsablesArray?.map((responsable) => {
              const {
                job,
                badge,
                type,
                titleModal,
                acceptsAppointments,
                canBeContacted,
                displayed,
              } = getDataByTypeOfResponsable(responsable?.type);

              if (!displayed) return null;

              return (
                <div className="contact" key={responsable?.id}>
                  <div className="bg-info badge-ec">{badge}</div>
                  <div className="name">
                    {responsable?.firstname + ' ' + responsable?.lastname}
                  </div>
                  <div className="job">{job}</div>
                  <div className="d-flex buttons">
                    {canBeContacted && (
                      <button
                        type="button"
                        className="btn btn-primary rounded-pill"
                        onClick={() => {
                          setTitleModal(titleModal);
                          setTypeMail(type);
                          setShowModalContact(true);
                        }}
                      >
                        Contacter
                      </button>
                    )}
                    {acceptsAppointments && (
                      <Feature name="RENDEZ_VOUS">
                        <Link
                          to={{ pathname: `/appointment/create` }}
                          className="btn btn-primary rounded-pill ms-2"
                        >
                          Rendez-vous
                        </Link>
                      </Feature>
                    )}
                  </div>
                  <User />
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}
