import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { convertMinsToHrsMins } from '@helpers';

import { ReactComponent as CalendarEmpty } from '@img/icons/calendar_empty.svg';
import { ReactComponent as Clock } from '@img/icons/clock.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import './card_rdv.scss';

export default function Mini_card_rdv({
  data,
  hasButton = true,
  hasDashboard = false,
  hasRecap = false,
  setShowModal,
  setModalData,
  setShowModalPlace,
  isLoading = false,
}) {
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (data && data.dateDebut && data.dateEcheance) {
      const dateEcheance = dayjs(data.dateEcheance);
      setDuration(dateEcheance.diff(data.dateDebut, 'minute'));
    }
  }, [data]);

  return (
    <div className="card card-rdv mini_card_rdv" data-cy="mini_card_rdv">
      <div
        className={`card-header d-flex ${
          hasDashboard && 'flex-wrap justify-content-between'
        }`}
      >
        <div className={`d-flex card-icon ${hasDashboard ? 'me-0' : ''}`}>
          <div className="icon">
            <CalendarEmpty className="float-start" />
          </div>
          <div className="text-start d-flex flex-column justify-content-center">
            <span
              className={`text-primary title-span d-none ${
                !hasDashboard ? 'd-md-inline' : ''
              }`}
            >
              Date
            </span>
            <h5 className={`text-primary ${hasDashboard ? 'fw-600' : ''}`}>
              {isLoading ? (
                <SkeletonTheme color="#c2dae6" highlightColor="#bedeef">
                  <Skeleton height={17} width={70} className="mb-1" />
                </SkeletonTheme>
              ) : (
                data &&
                data.dateDebut &&
                dayjs(data.dateDebut).locale('fr').format('DD/MM/YYYY')
              )}
            </h5>
          </div>
        </div>
        <div className={`d-flex card-icon ${hasDashboard ? 'me-0' : ''}`}>
          <div className="icon">
            <Clock className="float-start" />
          </div>
          <div className="text-start d-flex flex-column justify-content-center">
            <span
              className={`text-primary title-span d-none ${
                !hasDashboard ? 'd-md-inline' : ''
              }`}
            >
              Heure
            </span>
            <h5 className={`text-primary ${hasDashboard ? 'fw-600' : ''}`}>
              {isLoading ? (
                <SkeletonTheme color="#c2dae6" highlightColor="#bedeef">
                  <Skeleton height={17} width={30} className="mb-1" />
                </SkeletonTheme>
              ) : (
                data &&
                data?.dateDebut &&
                data &&
                data?.dateDebut &&
                dayjs(data?.dateDebut).locale('fr').format('HH:mm')
              )}
            </h5>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="card-text d-flex flex-wrap">
          <span className="d-flex card-icon">
            <span className="icon">
              <User className="float-start" />
            </span>
            <span className="text-start d-flex flex-column justify-content-center">
              <span
                className={`text-primary title-span d-none ${
                  !hasDashboard ? 'd-md-inline' : ''
                }`}
              >
                Votre interlocuteur
              </span>
              <span className={`h5 text-primary fw-bold`}>
                {isLoading ? (
                  <Skeleton height={17} width={125} className="mb-1" />
                ) : (
                  data &&
                  data?.utilisateursAssignes &&
                  data?.utilisateursAssignes[0]
                )}
              </span>
              <small className="text-placeholder">
                {data && data?.typeAction && data?.typeAction}
              </small>
            </span>
          </span>
          {(hasDashboard || hasRecap) && (
            <div className="">
              {data && data?.description && (
                <div className="d-flex card-icon">
                  <div className="icon">
                    <Question className="float-start" />
                  </div>
                  <div className="text-start d-flex flex-column justify-content-center">
                    <span
                      className={`text-primary title-span d-none ${
                        !hasDashboard ? 'd-md-inline' : ''
                      }`}
                    >
                      Motif
                    </span>
                    <p
                      className={`h5 text-primary ${
                        hasDashboard ? 'fw-bold' : ''
                      }`}
                    >
                      {data && data?.description && data?.description}
                    </p>
                    {duration && (
                      <small className="text-placeholder">
                        Durée estimée : {convertMinsToHrsMins(duration)}
                      </small>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {hasButton && (
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn rounded-pill btn-white-border fw-semi-bold"
              onClick={() => {
                setModalData(data);
                setShowModal(true);
              }}
            >
              Gérer le rendez-vous
            </button>
          </div>
        )}
        {/* <CalendarLogo className="calendar_logo" /> */}
      </div>
    </div>
  );
}
