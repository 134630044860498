import React, { useState, useEffect } from 'react';

import { getNotificationComponent } from './getNotificationComponant';

export const Notification = ({ type, ...props }) => {
  const [NotificationComponent, setNotificationComponent] = useState(null);

  useEffect(() => {
    const loadComponent = async () => {
      const component = await getNotificationComponent(type);
      setNotificationComponent(() => component);
    };

    loadComponent();
  }, [type]);

  if (!NotificationComponent) return null;

  return <NotificationComponent {...props} />;
};
