import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import { UserContext } from 'context/UserContext';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

import LoadingScreen from '@components/LoadingScreen/LoadingScreen';
import Modal from '@components/Modal/ModalDefault';
import NotificationBanner from '@components/NotificationBanner/NotificationBanner';

import { NewDocumentContext } from '@context/NewDocumentContext';

import {
  useSkynetEDFAClient,
  useSkynetDocumentCompleted,
  useListCertificationSubsidies,
} from '@hooks/useApi';

import CertificationSubsidies from './CertificationSubsidies';
import Bill from './bill';
import './documents.scss';
import OtherDocuments from './otherDocuments';

export default function Documents() {
  const { user } = useContext(UserContext);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [numberSlide, setNumberSlide] = useState(0);
  const [currentPage, setCurrentPage] = useState('bills_and_credits_docs');
  const [displayModalHasSigned, setDisplayModalHasSigned] = useState(false);
  const [displayModalHasNotSigned, setDisplayModalHasNotSigned] =
    useState(false);

  const { hasNewDocument, hasDocumentToSign, changeHasNewDocument } =
    useContext(NewDocumentContext);

  let location = useLocation();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 6,
    arrows: false,
  };

  useEffect(() => {
    if (location.hash && location.hash.includes('#other-doc')) {
      setCurrentPage('other_docs');
    } else if (
      location.hash &&
      location.hash.includes('#certification-subsidies')
    ) {
      setCurrentPage('certification_subsidies');
    }

    if (
      location.hash &&
      location.hash.includes('#other-doc?event=signing_complete') &&
      localStorage.getItem('envelope_id') !== null
    ) {
      axios
        .get(
          `${
            process.env.REACT_APP_BACK_URL
          }/api/skynet/envelopes/${localStorage.getItem('envelope_id')}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        )
        .then((res) => {
          if (
            res.data.userSigner !== null &&
            res.data.userStatus !== 'completed'
          ) {
            setDisplayModalHasNotSigned(true);
          } else {
            setDisplayModalHasSigned(true);
          }

          localStorage.removeItem('envelope_id');
        })
        .catch((error) => {});
    }
  }, [location]);

  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.documents').width();
      let nbNavItem = $('.nav-item').length || 3;
      $('.js-nav-link').outerWidth(containerWidth / nbNavItem - 6);
      let itemLength = $('.js-nav-link').length;
      let itemWidth = $('.js-nav-link').outerWidth(true);
      let numberSlideCalc = containerWidth / itemWidth;
      setNumberSlide(
        numberSlideCalc > itemLength ? itemLength : numberSlideCalc
      );
    }

    window.addEventListener('resize', handleResize);

    $('.nav-item .js-nav-link').on('click', function (e) {
      e.preventDefault();
      $('.js-nav-link').removeClass('active');
      $(this).addClass('active');
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      $('.nav-item .js-nav-link').off('click');
    };
  }, []);

  const {
    data: dataEdfaClient,
    isLoading: isLoadingEdfaClient,
    error: errorEdfaClient,
  } = useSkynetEDFAClient();
  const {
    data: dataDocumentsCompleted,
    isLoading: isLoadingDocumentsCompleted,
    error: errorDocumentsCompleted,
  } = useSkynetDocumentCompleted();

  const {
    data: certificationSubsidiesData,
    isLoading: certificationSubsidiesIsLoading,
    error: certificationSubsidiesError,
  } = useListCertificationSubsidies({
    enabled: !!user?.meyClubId,
  });

  const isLoadingData = isLoadingEdfaClient || isLoadingDocumentsCompleted;
  const errorData = errorEdfaClient || errorDocumentsCompleted;

  useEffect(() => {
    if (dataDocumentsCompleted) {
      const nbNotViewCalcul = dataDocumentsCompleted.filter(
        (item) => item.view === false
      ).length;

      if (nbNotViewCalcul === 0) {
        changeHasNewDocument(false);
      }
    }
  }, [dataDocumentsCompleted]);

  return (
    <div className="w-100 documents">
      {loadingDownload && <LoadingScreen />}
      <h3 className="title_page">Mes documents</h3>
      {hasDocumentToSign ? (
        <NotificationBanner
          content="Vous avez des documents en attente de signature."
          link="/documents-to-sign"
          linkText="Signer les documents"
          color="info"
        />
      ) : (
        ''
      )}
      <div data-cy="slider-document">
        <Slider
          {...settings}
          className={`slider slider-document slider-ec nav nav-tabs ${
            isLoadingData && 'pointer-events-none'
          }`}
        >
          <li className="nav-item">
            <a
              className={`js-nav-link nav-link ${
                currentPage === 'bills_and_credits_docs' && 'active'
              }`}
              id="bill-tab"
              data-bs-toggle="tab"
              href="#bill"
              role="tab"
              aria-controls="bill"
              aria-selected="true"
              onClick={() => setCurrentPage('bills_and_credits_docs')}
            >
              Mes factures & Avoirs
            </a>
          </li>

          {user?.meyClubId && (
            <li className={`nav-item d-flex justify-content-end`}>
              <a
                className={`js-nav-link nav-link ${
                  currentPage === 'certification_subsidies' && 'active'
                }`}
                id="attestation-doc-tab"
                data-bs-toggle="tab"
                href="#attestation-doc"
                role="tab"
                aria-controls="other-doc"
                aria-selected="false"
                onClick={() => setCurrentPage('certification_subsidies')}
              >
                Attestations de subvention
              </a>
            </li>
          )}
          <li
            className={`nav-item ${
              hasNewDocument ? 'has-new-document' : ''
            } d-flex justify-content-end`}
          >
            <a
              className={`js-nav-link nav-link ${
                currentPage === 'other_docs' && 'active'
              }`}
              id="other-doc-tab"
              data-bs-toggle="tab"
              href="#other-doc"
              role="tab"
              aria-controls="other-doc"
              aria-selected="false"
              onClick={() => setCurrentPage('other_docs')}
            >
              Autres documents
            </a>
          </li>
        </Slider>
      </div>
      <div className="tab-content">
        <div
          className={`tab-pane fade ${
            currentPage === 'bills_and_credits_docs' && 'show active'
          }`}
          id="bill"
          role="tabpanel"
          aria-labelledby="bill-tab"
        >
          <Bill
            loading={isLoadingData}
            error={errorData}
            setLoadingDownload={setLoadingDownload}
            data={dataEdfaClient}
          />
        </div>

        <div
          className={`tab-pane fade ${
            currentPage === 'certification_subsidies' && 'show active'
          }`}
          id="bill"
          role="tabpanel"
          aria-labelledby="attestation-tab"
        >
          <CertificationSubsidies
            loading={certificationSubsidiesIsLoading}
            error={certificationSubsidiesError}
            setLoadingDownload={setLoadingDownload}
            data={certificationSubsidiesData}
          />
        </div>
        <div
          className={`tab-pane fade ${
            currentPage === 'other_docs' && 'show active'
          }`}
          id="other-doc"
          role="tabpanel"
          aria-labelledby="other-doc-tab"
        >
          <OtherDocuments
            loading={isLoadingData}
            error={errorData}
            setLoadingDownload={setLoadingDownload}
            data={dataDocumentsCompleted}
          />
        </div>
        <Modal
          show={displayModalHasSigned}
          handleClose={() => {
            setDisplayModalHasSigned(false);
          }}
          className="modal_document"
        >
          <div className="">
            <h2 className="fw-bold text-primary text-center">
              Un peu de patience...
            </h2>
            <p>
              Le document signé apparaîtra d'ici quelques minutes dans "Mes
              documents".
            </p>
            <div className="text-center button">
              <button
                type="button"
                className="btn rounded-pill btn-primary fw-semi-bold"
                onClick={() => {
                  setDisplayModalHasSigned(false);
                }}
              >
                Fermer
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={displayModalHasNotSigned}
          handleClose={() => {
            setDisplayModalHasNotSigned(false);
          }}
          className="modal_document"
        >
          <div className="">
            <h2 className="fw-bold text-primary text-center">
              Nous devons signer le document
            </h2>
            <p>
              Vous pourrez consulter et télecharger ce document dès que nous
              aurons signé le document également.
            </p>
            <div className="text-center button">
              <button
                type="button"
                className="btn rounded-pill btn-primary fw-semi-bold"
                onClick={() => {
                  setDisplayModalHasNotSigned(false);
                }}
              >
                Fermer
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
