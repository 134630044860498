import NotificationCancel from '../../organisms/NotificationType/NotificationCancel/NotificationCancel';
import NotificationDefault from '../../organisms/NotificationType/NotificationDefault/NotificationDefault';
import NotificationError from '../../organisms/NotificationType/NotificationError/NotificationError';
import NotificationPending from '../../organisms/NotificationType/NotificationPending/NotificationPending';
import NotificationRefuse from '../../organisms/NotificationType/NotificationRefuse/NotificationRefuse';
import NotificationSuccess from '../../organisms/NotificationType/NotificationSuccess/NotificationSuccess';

const notificationComponents = {
  cancel: NotificationCancel,
  refuse: NotificationRefuse,
  error: NotificationError,
  pending: NotificationPending,
  success: NotificationSuccess,
  default: NotificationDefault,
};

export function getNotificationComponent(type) {
  return notificationComponents[type] || notificationComponents.default;
}
