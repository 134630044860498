import React, { useState } from 'react';

import AccountValidated from './AccountValidated/AccountValidated';
import EnterContactEmail from './EnterContactEmail/EnterContactEmail';
import MailNotExist from './MailNotExist/MailNotExist';
import UserNotExist from './UserNotExist/UserNotExist';
import UserNotVerified from './UserNotVerified/UserNotVerified';
import './difficultyToConnect.scss';

export default function DifficultyToConnect() {
  const [step, setStep] = useState(null);
  const [email, setEmail] = useState('');

  const onChangeEmail = (e) => {
    let value = e.target.value.toLowerCase();

    setEmail(value);
  };

  switch (step) {
    case 'mail_not_exist':
      return <MailNotExist />;
    case 'user_not_exist':
      return <UserNotExist />;
    case 'user_not_verified':
      return (
        <UserNotVerified email={email} setEmail={setEmail} setStep={setStep} />
      );
    case 'account_validated':
      return (
        <AccountValidated email={email} setEmail={setEmail} setStep={setStep} />
      );
    default:
      return (
        <EnterContactEmail
          onChangeEmail={onChangeEmail}
          email={email}
          setStep={setStep}
        />
      );
  }
}
