import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const DashboardSkeleton = () => {
  return (
    <div className="w-100 dashboard">
      <div className="row me-md-0">
        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3  d-flex gap-3"
          data-cy="widget-wallet"
        >
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} width="100%" className="mb-3" />
                <div className="d-flex justify-center flex-column w-100 gap-3">
                  <Skeleton height={87} width="100%" className="" />

                  <Skeleton height={87} width="100%" className="" />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        </div>

        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv d-flex gap-3"
          data-cy="widget-rdv"
        >
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%" height="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} width="60%" className="mb-3" />
                <Skeleton height={159} width="95%" className="mt-4 mb-4" />
                <div className="text-center w-100">
                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-4 rounded-pill mt-3"
                  />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        </div>
        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv d-flex gap-3"
          data-cy="widget-rdv"
        >
          <div className="col-12 skeleton center">
            <div className="h-100">
              <section className="d-flex flex-column align-items-center justify-content-between w-100 h-100">
                <Skeleton height={20} className="mb-5" />
                <div className="d-flex flex-column align-items-center justify-content-between w-100">
                  <Skeleton height={20} width="40%" className="mb-2" />
                  <Skeleton height={20} className="mb-4" />
                  <Skeleton height={20} width="40%" className="mb-1" />
                  <Skeleton height={20} width="30%" className="mb-5" />
                </div>
                <div className="text-center w-100">
                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-4 rounded-pill"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv d-flex gap-3"
          data-cy="widget-rdv"
        >
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} width="40%" className="mb-4" />
                <div className="d-flex w-100 skeleton-interlocuteurs">
                  <Skeleton height={154} width="95%" className="" />

                  <Skeleton height={154} width="95%" className="" />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        </div>
        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv d-flex gap-3"
          data-cy="widget-rdv"
        >
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section>
                <Skeleton height={20} className="mb-4" />

                <Skeleton height={20} className="mb-1" />
                <Skeleton height={20} width="40%" className="mb-3" />

                <Skeleton height={20} className="mb-1" />
                <Skeleton height={20} width="40%" className="mb-3" />

                <Skeleton height={20} className="mb-1" />
                <Skeleton height={20} className="mb-1" />
                <Skeleton height={20} width="40%" className="mb-3" />
                <div className="text-center w-100">
                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-4 rounded-pill"
                  />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        </div>
        <div
          className="widget rect_info col-12 col-sm-6 col-xxl-4 mb-2 mb-md-3 rdv d-flex gap-3"
          data-cy="widget-rdv"
        >
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} className="mb-4" />
                <div className="d-flex w-100 skeleton-interlocuteurs mb-3">
                  <Skeleton height={154} width="95%" className="" />

                  <Skeleton height={154} width="95%" className="" />
                </div>
                <div className="text-center w-100">
                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-4 rounded-pill"
                  />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
