import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import './chip.scss';

export const Chip = forwardRef(
  (
    {
      backgroundColorName = 'primary',
      textColor,
      textWeight = 'bold',
      fontSize = '11px',
      lineHeight = '24px',
      padding = '0px 12px',
      margin = '0px 0px 22px 0px',
      size,
      label,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={[
          'ec-chip',
          'rounded-pill',
          `bg-${backgroundColorName}`,
          `text-${textColor}`,
          `fw-${textWeight}`,
          startIcon || endIcon ? 'd-inline-flex gap-2 align-items-center' : '',
        ].join(' ')}
        style={{
          fontSize: fontSize,
          lineHeight: lineHeight,
          padding: padding,
          margin: margin,
        }}
        {...props}
      >
        {startIcon}
        {label}
        {endIcon}
      </div>
    );
  }
);

Chip.propTypes = {
  backgroundColorName: PropTypes.string,
  textColor: PropTypes.string,
  textWeight: PropTypes.oneOf(['bold', 'bolder', 'normal', 'light', 'lighter']),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

Chip.defaultProps = {
  backgroundColorName: 'primary',
  textColor: 'white',
  textWeight: 'bold',
  fontSize: '11px',
  lineHeight: '24px',
  padding: '0px 12px',
  margin: '0px 0px 22px 0px',
  startIcon: null,
  endIcon: null,
};
