import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '../../atoms';

export const LinkButton = ({
  style = 'primary',
  backgroundColor,
  size,
  label,
  to,
  target,
  ...props
}) => {
  return (
    <Link
      to={to}
      target={target}
      style={{
        margin: '0 auto',
      }}
    >
      <Button
        style={style}
        backgroundColor={backgroundColor}
        size={size}
        label={label}
        {...props}
      />
    </Link>
  );
};

LinkButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,

  to: PropTypes.object,
  target: PropTypes.string,
};

LinkButton.defaultProps = {
  backgroundColor: null,
  style: 'primary',
  size: 'medium',
  onClick: undefined,
  target: '_self',
};
