import React, { useState, useRef, useEffect, useMemo } from 'react';

import dayjs from 'dayjs';
import { useSkynetCalendarBusySlot } from 'hooks/useApi';
import Skeleton from 'react-loading-skeleton';
import Carousel from 'stories/organisms/Carousel/Carousel';
import { Controller } from 'swiper/modules';

import { convertTimeToMs, getRandomInt } from '@helpers';

import './BookingCarousel.scss';
import {
  createSliderWeek,
  getAvailableDates,
  isSameMonth,
  switchToNextMonthIfNoAvailableDates,
} from './BookingUtils';

const Creneau = ({ dateCreneau, isSelected, onClickHour }) => {
  return (
    <div
      className={`hour ${isSelected ? 'selected' : ''}`}
      data-cy="hour"
      data-date={dateCreneau}
      onClick={onClickHour}
    >
      {dayjs(dateCreneau).format('H:mm')}
    </div>
  );
};

const BookingCarousel = ({ date, setDate, format, motif, setIsSelected }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const skeleton = [2, 5, 6, 1, 4];

  const breakpoints = {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    992: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1150: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1300: {
      slidesPerView: 5,
      slidesPerGroup: 5,
    },
  };

  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    date ? dayjs(date).format('MM/01/YYYY') : dayjs().format('MM/01/YYYY')
  );
  const [nbDaysInSelectedMonth, setNbDaysInSelectedMonth] = useState(null);

  const [listDays, setListDays] = useState(null);
  const [creneaux, setCreneaux] = useState(null);

  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  const {
    data: dataCalendar,
    isLoading: isLoadingCalendar,
    isFetching: isFetchingCalendar,
    isSuccess,
  } = useSkynetCalendarBusySlot(format, motif, selectedMonth, isSameMonth, {
    enabled:
      format !== null &&
      motif !== null &&
      motif !== undefined &&
      selectedMonth !== null &&
      String(format !== 0 ? 30 : parseInt(motif?.duration)) !== 'NaN',
    cacheTime: convertTimeToMs(1, 'minute'),
    staleTime: convertTimeToMs(1, 'minute'),
    refetchInterval: convertTimeToMs(1, 'minute'),
  });

  const sliderGoTo = (index) => {
    index = parseInt(index);

    setTimeout(() => {
      if (slider1Ref && slider2Ref) {
        slider1Ref?.current?.swiper?.slideTo(index);
        slider2Ref?.current?.swiper?.slideTo(index);
      }
    }, 500);
  };

  const getCreneaux = (startDate, interval, availabilityView) => {
    const availableDates = getAvailableDates(
      startDate,
      interval,
      availabilityView
    );

    switchToNextMonthIfNoAvailableDates(
      availableDates,
      months,
      selectedMonth,
      onClickMonth
    );

    const creneaux = [];
    listDays.forEach((item) => {
      const result = availableDates.filter(
        (date) =>
          dayjs(date).format('MM/DD/YY') === dayjs(item.date).format('MM/DD/YY')
      );
      creneaux.push(result);
    });

    setCreneaux(creneaux);

    setTimerForHourSelection(availableDates);
  };

  const setTimerForHourSelection = (availableDates) => {
    setTimeout(() => {
      const hours = document.querySelectorAll('.hour');
      if (hours.length) {
        const targetDate =
          date && dayjs(date).isSame(selectedMonth, 'month')
            ? date
            : availableDates[0];
        const selectedHour = document.querySelector(
          `.hour[data-date="${targetDate}"]`
        );

        if (selectedHour) {
          selectedHour.classList.add('selected');
          setIsSelected(true);
          setDate(targetDate);

          const swiperSlide = selectedHour.closest('.swiper-slide');
          if (swiperSlide) {
            const index = swiperSlide.getAttribute('data-swiper-slide-index');
            sliderGoTo(index);
          }
        }
      }
    }, 100);
  };

  useEffect(() => {
    setMonths([
      {
        monthId: dayjs().format('MM/01/YYYY'),
        name: dayjs().locale('fr').format('MMMM YYYY'),
      },
      {
        monthId: dayjs().add(1, 'month').format('MM/01/YYYY'),
        name: dayjs().locale('fr').add(1, 'month').format('MMMM YYYY'),
      },
      {
        monthId: dayjs().add(2, 'month').format('MM/01/YYYY'),
        name: dayjs().locale('fr').add(2, 'month').format('MMMM YYYY'),
      },
    ]);
    setNbDaysInSelectedMonth(dayjs(selectedMonth).daysInMonth());
  }, []);

  const onClickMonth = (monthID) => {
    setSelectedMonth(monthID);
    setCreneaux(null);
    setListDays(null);

    sliderGoTo(0);

    createSliderWeek(
      monthID,
      dayjs(monthID).daysInMonth(),
      setListDays,
      listDays
    );
    setNbDaysInSelectedMonth(dayjs(monthID).daysInMonth());
  };

  useEffect(() => {
    if (
      isSuccess &&
      dataCalendar !== null &&
      dataCalendar !== undefined &&
      listDays !== null
    ) {
      setTimeout(() => {
        getCreneaux(
          dataCalendar.startDate,
          format !== 0 ? 30 : parseInt(motif.duration),
          dataCalendar.availabilityView
        );
      }, 100);
    }
  }, [dataCalendar, isSuccess, listDays, isFetchingCalendar]);

  useEffect(() => {
    setTimeout(() => {
      if (
        (!isLoadingCalendar || !isFetchingCalendar) &&
        slider1Ref &&
        slider2Ref &&
        creneaux !== null
      ) {
        slideToSelectedHour();
      }
    }, 500);

    if (listDays !== null && !isLoadingCalendar && motif !== null) {
      const randomTab = [];
      listDays.forEach((days) => {
        randomTab.push(getRandomInt(2, 6));
      });
    }
  }, [
    creneaux,
    slider1Ref,
    slider2Ref,
    listDays,
    dataCalendar,
    isLoadingCalendar,
    motif,
  ]);

  const slideToSelectedHour = (isOnResize) => {
    setTimeout(
      () => {
        const element = document
          .querySelector('.carousel-hours .hour.selected')
          ?.closest('.swiper-slide')
          ?.getAttribute('data-swiper-slide-index');

        setTimeout(() => {
          sliderGoTo(element);
        }, 100);
      },
      isOnResize ? 1000 : 0
    );
  };

  const onClickHour = (e, date) => {
    setIsSelected(true);
    setDate(date);
  };

  const sliderDaysItems = useMemo(() => {
    return nbDaysInSelectedMonth !== null
      ? createSliderWeek(
          selectedMonth,
          nbDaysInSelectedMonth,
          setListDays,
          listDays
        )
      : [];
  }, [selectedMonth, nbDaysInSelectedMonth, listDays]);

  return (
    <div
      className={`booking-carousel-container d-flex flex-column align-items-center w-100 ${
        isLoadingCalendar ? 'disabled' : ''
      }`}
    >
      <div className="selector-month">
        {months.map((month, index) => {
          return (
            <div
              className={`month ${
                selectedMonth === month.monthId ? 'selected' : ''
              } ${isLoadingCalendar ? 'disabled' : ''}`}
              onClick={() => onClickMonth(month.monthId)}
              key={month.monthId + month.name}
            >
              {month.name}
            </div>
          );
        })}
      </div>
      {nbDaysInSelectedMonth !== null && (
        <div className="slider-container">
          <div className="slider-header">
            <Carousel
              ref={slider1Ref}
              className="carousel-days"
              autoPlay={false}
              loop={false}
              hasPagination={false}
              modules={[Controller]}
              controller={{ control: controlledSwiper }}
              slidesPerView={5}
              slidesPerGroup={5}
              breakpoints={breakpoints}
              onResize={() => slideToSelectedHour(true)}
              // data-cy="carousel-notifications"
              items={sliderDaysItems}
            />
          </div>
          <Carousel
            ref={slider2Ref}
            className="carousel-hours mx-auto"
            autoPlay={false}
            hasPagination={false}
            hasNavigation={false}
            slidesPerView={5}
            slidesPerGroup={5}
            breakpoints={breakpoints}
            spaceBetween={30}
            modules={[Controller]}
            onSwiper={setControlledSwiper}
            // data-cy="carousel-notifications"
            items={
              (isLoadingCalendar || isFetchingCalendar) &&
              listDays !== null &&
              creneaux === null
                ? listDays.map((day, index) => {
                    return day.isHoliday ? (
                      <div key={index}></div>
                    ) : (
                      <div key={index} className="div-skeleton">
                        {[...Array(skeleton[index])].map((e, i) => (
                          <Skeleton className="hour skeleton" key={i} />
                        ))}
                      </div>
                    );
                  })
                : creneaux !== null &&
                  creneaux.map((creneau, index) => {
                    return (
                      <div data-cy="creneaux" key={index}>
                        {creneau.length
                          ? creneau.map((dateCreneau, index) => {
                              return (
                                <Creneau
                                  key={index}
                                  dateCreneau={dateCreneau}
                                  isSelected={date === dateCreneau}
                                  onClickHour={(e) =>
                                    onClickHour(e, dateCreneau)
                                  }
                                />
                              );
                            })
                          : ''}
                      </div>
                    );
                  })
            }
          />
        </div>
      )}
    </div>
  );
};

export default BookingCarousel;
