import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Loader from '@components/Loader/Loader';

import { getQueryParameters } from '@helpers';

import './pdfViewer.scss';

export default function PdfViewer() {
  const [pdf, setPdf] = useState('');
  const [loading, setLoading] = useState(true);
  const { pdfId } = useParams();
  let location = useLocation();

  useEffect(() => {
    const queryParams = getQueryParameters(location.search);

    let request = `${process.env.REACT_APP_BACK_URL}/api/skynet/edfa/pdf/${pdfId}`;
    if (queryParams.isDocument) {
      request = `${process.env.REACT_APP_BACK_URL}/api/skynet/document/${pdfId}`;
    } else if (queryParams.isSubsidy) {
      request = `${process.env.REACT_APP_BACK_URL}/api/document/view_certification_subsidy/${pdfId}`;
    }

    axios
      .get(request, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        // setData(JSON.parse(res.data.infos))
        setLoading(false);

        const queryParams = getQueryParameters(location.search);
        let pdfData = '';
        if (queryParams.isSubsidy) {
          pdfData = res.data.data;
        } else if (queryParams.isDocument) {
          pdfData = res.data.document;
        } else {
          pdfData = JSON.parse(res.data);
        }

        setPdf(pdfData);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [pdfId, location.search]);

  return loading ? (
    <div className="min-height-full w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="d-block fw-bold mb-5 text-primary">
        Chargement de votre document...
      </h1>
      <Loader />
    </div>
  ) : (
    <object
      id="pdf"
      data={pdf}
      type="application/pdf"
      aria-label="pdf"
    ></object>
  );
}
