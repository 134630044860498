import React, { forwardRef, useEffect } from 'react';

import 'swiper/css/bundle';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Carousel.scss';
import ArrowButton from './components/ArrowButton/ArrowButton';

const Carousel = forwardRef(
  (
    {
      items,
      hasNavigation = true,
      hasPagination = true,
      slidesPerView = 1,
      className = '',
      arrowsProps,
      arrowNextProps,
      arrowPrevProps,
      autoPlay = true,
      autoPlaySpeed = 5000,
      loop = true,
      modules = [],
      ...props
    },
    ref
  ) => {
    const swiper = ref?.current?.swiper;

    useEffect(() => {
      if (swiper) {
        swiper.navigation.update();
      }
    }, [swiper]);

    const formatItems = items
      ? items.filter((item) => item !== null && item !== false)
      : [];

    return (
      <div
        data-cy="carousel-equipement-container"
        className={[
          'swiper-container',
          hasNavigation && 'swiper-container--with-navigation',
          className,
        ].join(' ')}
      >
        {hasNavigation && (
          <ArrowButton
            direction={'prev'}
            {...arrowPrevProps}
            {...arrowsProps}
          />
        )}
        <Swiper
          ref={ref}
          spaceBetween={50}
          modules={[Navigation, Pagination, Autoplay, ...modules]}
          navigation={{
            nextEl: '.arrow--next',
            prevEl: '.sarrow--prev',
            disabledClass: 'arrow--disabled',
            enabled: formatItems?.length > 1 && hasNavigation,
          }}
          pagination={{
            clickable: true,
            enabled: formatItems?.length > 1 && hasPagination,
          }}
          autoplay={
            autoPlay && {
              delay: autoPlaySpeed,
              disableOnInteraction: false,
            }
          }
          loop={formatItems?.length > 1 && loop}
          slidesPerView={slidesPerView}
          breakpoints={{
            0: {
              navigation: {
                enabled: false,
              },
            },
            768: {
              navigation: {
                enabled: true,
              },
            },
          }}
          onBeforeInit={(swiper) => {
            if (
              swiper.params.navigation &&
              typeof swiper.params.navigation !== 'boolean'
            ) {
              swiper.params.navigation.prevEl = '.arrow--prev';
              swiper.params.navigation.nextEl = '.arrow--next';
            }
          }}
          {...props}
        >
          {formatItems?.map((item, index) => (
            <SwiperSlide key={index}>{item}</SwiperSlide>
          ))}
        </Swiper>
        {hasNavigation && (
          <ArrowButton
            direction={'next'}
            {...arrowNextProps}
            {...arrowsProps}
          />
        )}
      </div>
    );
  }
);

export default Carousel;
