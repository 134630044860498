import React from 'react';

import NotificationDefault from '../NotificationDefault/NotificationDefault';

const NotificationCancel = ({ ...props }) => {
  return (
    <NotificationDefault
      borderColor="start-color"
      icon={
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="info-icon mt-2 mt-md-0"
        >
          <path
            d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3336C29.1203 20.8668 30 17.9667 30 15C29.9957 11.0231 28.414 7.21026 25.6019 4.39815C22.7897 1.58604 18.9769 0.00430135 15 0V0ZM15.3125 6.25C15.6833 6.25 16.0459 6.35997 16.3542 6.56599C16.6625 6.77202 16.9029 7.06486 17.0448 7.40747C17.1867 7.75008 17.2238 8.12708 17.1515 8.49079C17.0791 8.85451 16.9006 9.1886 16.6383 9.45083C16.3761 9.71305 16.042 9.89163 15.6783 9.96397C15.3146 10.0363 14.9376 9.99919 14.595 9.85727C14.2524 9.71536 13.9595 9.47504 13.7535 9.16669C13.5475 8.85835 13.4375 8.49584 13.4375 8.125C13.4375 7.62772 13.6351 7.15081 13.9867 6.79917C14.3383 6.44754 14.8152 6.25 15.3125 6.25ZM18.125 23.125H13.125C12.7935 23.125 12.4755 22.9933 12.2411 22.7589C12.0067 22.5245 11.875 22.2065 11.875 21.875C11.875 21.5435 12.0067 21.2255 12.2411 20.9911C12.4755 20.7567 12.7935 20.625 13.125 20.625H14.0625C14.1454 20.625 14.2249 20.5921 14.2835 20.5335C14.3421 20.4749 14.375 20.3954 14.375 20.3125V14.6875C14.375 14.6046 14.3421 14.5251 14.2835 14.4665C14.2249 14.4079 14.1454 14.375 14.0625 14.375H13.125C12.7935 14.375 12.4755 14.2433 12.2411 14.0089C12.0067 13.7745 11.875 13.4565 11.875 13.125C11.875 12.7935 12.0067 12.4755 12.2411 12.2411C12.4755 12.0067 12.7935 11.875 13.125 11.875H14.375C15.038 11.875 15.6739 12.1384 16.1428 12.6072C16.6116 13.0761 16.875 13.712 16.875 14.375V20.3125C16.875 20.3954 16.9079 20.4749 16.9665 20.5335C17.0251 20.5921 17.1046 20.625 17.1875 20.625H18.125C18.4565 20.625 18.7745 20.7567 19.0089 20.9911C19.2433 21.2255 19.375 21.5435 19.375 21.875C19.375 22.2065 19.2433 22.5245 19.0089 22.7589C18.7745 22.9933 18.4565 23.125 18.125 23.125Z"
            fill="#25C8EB"
          ></path>
        </svg>
      }
      {...props}
    />
  );
};

export default NotificationCancel;
