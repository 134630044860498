import React from 'react';

import NotificationDefault from '../NotificationDefault/NotificationDefault';

const NotificationSucces = ({ ...props }) => {
  return (
    <NotificationDefault
      borderColor="validation"
      icon={
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 2L8.24889 20.2249C7.65778 21.0725 6.47556 21.2421 5.63111 20.6487C5.46222 20.5639 5.29333 20.3944 5.20889 20.2249L2 15.9018"
            stroke="#39B54A"
            strokeWidth="4px"
            strokeMiterlimit="23.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          ></path>
        </svg>
      }
      {...props}
    />
  );
};

export default NotificationSucces;
