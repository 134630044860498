import React from 'react';

import NotificationDefault from '../NotificationDefault/NotificationDefault';

const NotificationError = ({ ...props }) => {
  return (
    <NotificationDefault
      borderColor="error"
      icon={
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.6063 4.41779C22.7917 1.60484 18.9749 0.0251957 14.9956 0.0263678C11.0163 0.02754 7.20047 1.60943 4.38752 4.42404C1.57456 7.23865 -0.00507767 11.0554 -0.0039056 15.0347C-0.00273353 19.014 1.57916 22.8298 4.39377 25.6428C7.16645 28.3562 10.8733 29.9043 14.7523 29.9687C18.6313 30.0331 22.3875 28.609 25.2488 25.989C26.5027 24.8163 27.5472 23.4382 28.3375 21.914C29.0928 20.4477 29.5996 18.8662 29.8375 17.234C29.9447 16.5043 29.999 15.7678 30 15.0303C30.0054 13.0588 29.6199 11.1057 28.8657 9.28412C28.1115 7.46253 27.0037 5.80853 25.6063 4.41779ZM6.61502 6.61529C8.61588 4.65588 11.2377 3.45602 14.0285 3.22258C16.8192 2.98915 19.6039 3.73677 21.9025 5.33654C21.941 5.36463 21.973 5.40072 21.9962 5.44234C22.0194 5.48395 22.0333 5.53011 22.037 5.57762C22.0407 5.62513 22.034 5.67287 22.0175 5.71757C22.001 5.76227 21.975 5.80285 21.9413 5.83654L5.83377 21.9515C5.79998 21.9851 5.75937 22.0109 5.7147 22.0273C5.67002 22.0437 5.62234 22.0503 5.5749 22.0466C5.52745 22.0429 5.48135 22.0291 5.43974 22.006C5.39813 21.9829 5.36199 21.9511 5.33377 21.9128C3.70225 19.6238 2.93561 16.8303 3.17022 14.0292C3.40483 11.2281 4.62547 8.60099 6.61502 6.61529ZM23.4363 23.4453C21.4485 25.4282 18.8235 26.6441 16.0255 26.8782C13.2276 27.1122 10.4371 26.3492 8.14752 24.724C8.10893 24.696 8.07687 24.66 8.05354 24.6185C8.03021 24.5769 8.01616 24.5308 8.01236 24.4833C8.00856 24.4357 8.0151 24.388 8.03152 24.3432C8.04795 24.2985 8.07388 24.2578 8.10752 24.224L24.2163 8.10779C24.2501 8.07428 24.2907 8.04844 24.3353 8.03203C24.38 8.01562 24.4277 8.00903 24.4751 8.01271C24.5226 8.01639 24.5687 8.03024 24.6103 8.05334C24.6519 8.07643 24.688 8.10822 24.7163 8.14654C26.3484 10.4353 27.1157 13.229 26.8815 16.0303C26.6474 18.8317 25.427 21.4592 23.4375 23.4453H23.4363Z"
            fill="#E80000"
          ></path>
        </svg>
      }
      {...props}
    />
  );
};

export default NotificationError;
