import React, { useContext, useState, useEffect } from 'react';

import $ from 'jquery';

import { SelectList } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { UserContext } from '@context/UserContext';

import { useSkynetMainResponsable } from '@hooks/useApi';
import { useSkynetEmailPost } from '@hooks/useApi';

import { ReactComponent as PieceJointe } from '@img/icons/piece-jointe.svg';

import './contact.scss';

export default function Contact() {
  const [textareaValue, setTextareaValue] = useState('');
  const [motifSelect, setMotifSelect] = useState('');
  const [sousMotifSelect, setSousMotifSelect] = useState('');
  const [motifNv3Select, setMotifNv3Select] = useState('');
  const [options, setOptions] = useState([]);
  const [optionsSousMotif, setOptionsSousMotif] = useState([]);
  const [optionsMotifNv3, setOptionsMotifNv3] = useState([]);
  const [fileName, setFileName] = useState('');
  const [filesList, setFilesList] = useState([]);
  const [errorSize, setErrorSize] = useState(false);
  const [errorFormat, setErrorFormat] = useState(false);
  const [messageSend, setMessageSend] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    switch (true) {
      case user.platform.includes('P50'):
        setOptions(require('@datas/motifsSupport/motifsSupportP50.json'));
        break;
      case user.platform.includes('V12'):
        setOptions(require('@datas/motifsSupport/motifsSupportV12.json'));
        break;

      default:
        setOptions(require('@datas/motifsSupport/motifsSupportDefault.json'));
        break;
    }
  }, []);

  useEffect(() => {
    if (
      textareaValue.trim() === '' ||
      motifSelect === '' ||
      sousMotifSelect === '' ||
      (optionsMotifNv3.length && motifNv3Select === '')
    ) {
      setButtonIsDisabled(true);
    } else {
      setButtonIsDisabled(false);
    }
  }, [
    textareaValue,
    motifSelect,
    sousMotifSelect,
    optionsMotifNv3,
    motifNv3Select,
  ]);

  const {
    mutate: mutatePostEmailSupport,
    isLoading: isLoadingPostEmailSupport,
  } = useSkynetEmailPost({
    onSuccess: (data) => {
      setMessageSend(true);
      setMotifSelect('');
      setSousMotifSelect('');
      setMotifNv3Select('');
      setTextareaValue('');
      setFileName('');
      setFilesList([]);

      $('#root').animate(
        {
          scrollTop: $('#root').offset().top,
        },
        0
      );
    },
  });

  const { data: dataMainResponsable } = useSkynetMainResponsable();

  useEffect(() => {
    $('#root').animate(
      {
        scrollTop: $('#root').offset().top,
      },
      0
    );

    $('.nav-item .js-nav-link').on('click', function (e) {
      e.preventDefault();
      $('.js-nav-link').removeClass('active');
      $(this).addClass('active');
    });

    return () => {
      $('.nav-item .js-nav-link').off('click');
    };
  }, []);

  const onChangeSelect = (e) => {
    setOptionsSousMotif(e.sousMotifs || []);
    setSousMotifSelect('');
    setMotifNv3Select('');
    setOptionsMotifNv3([]);
    setMotifSelect(e);
  };

  const onChangeSelectSousMotif = (e) => {
    setOptionsMotifNv3(e.sousMotifs || []);
    setMotifNv3Select('');
    setSousMotifSelect(e);
  };

  const onChangeSelectMotifNv3 = (e) => {
    setMotifNv3Select(e);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getFileExtension = (fname) => {
    return fname
      .slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1)
      .toLowerCase();
  };

  const onFilesChange = (e) => {
    setErrorSize(false);
    setErrorFormat(false);
    let file = e.target.files[0];
    let acceptFormat = ['jpg', 'jpeg', 'png'];

    if (file !== undefined) {
      if (
        file.size <= 20000000 &&
        acceptFormat.includes(getFileExtension(file.name))
      ) {
        setFileName(file.name);
        setFilesList(file);
      } else {
        e.target.value = '';

        file.size > 20000000 && setErrorSize(true);
        acceptFormat.includes(getFileExtension(file.name)) === false &&
          setErrorFormat(true);
      }
    } else {
      setFileName('');
      setFilesList([]);
    }
  };

  const sendMail = async (e) => {
    e.preventDefault();

    let data = {
      motif: motifSelect.value,
      sous_motif: sousMotifSelect.value,
      email_support:
        sousMotifSelect?.platform?.at(0)?.email ??
        motifNv3Select?.platform?.at(0)?.email ??
        false,
      content: textareaValue,
    };

    if (motifNv3Select !== '') data.sous_sous_motif = motifNv3Select.value;

    if (filesList.length === undefined) {
      data.file = await toBase64(filesList);
      data.fileName = filesList.name;
    }

    mutatePostEmailSupport({
      typeMail: 'support',
      data: data,
    });
  };

  return (
    <div className="w-100 faq">
      <div className="header-faq">
        <h3 className="title_page">Contact</h3>
      </div>

      <div className="nav nav-tabs">
        <li className="nav-item">
          <a
            className="js-nav-link nav-link active"
            id="message-tab"
            data-bs-toggle="tab"
            href="#message"
            role="tab"
            aria-controls="message"
            aria-selected="true"
          >
            Par message
          </a>
        </li>
        <li className="nav-item">
          <a
            className="js-nav-link nav-link"
            id="phone-tab"
            data-bs-toggle="tab"
            href="#phone"
            role="tab"
            aria-controls="phone"
            aria-selected="false"
          >
            Par téléphone
          </a>
        </li>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12 px-4">
            <div className="tab-content" id="myTabContent">
              <>
                <div
                  className="tab-pane fade show active "
                  id="message"
                  role="tabpanel"
                  aria-labelledby="message-tab"
                >
                  {!messageSend ? (
                    <div className="row px-3 px-md-0">
                      <h5 className="fw-bold text-primary rect_info_title col-12">
                        Sélectionnez le motif et rédigez votre message. Nous
                        vous répondrons dans les plus brefs délais.
                      </h5>
                      <small className="text-primary col-12 demande-solution">
                        Pour toute demande concernant votre solution (aide à
                        l'utilisation ou remontée de dysfonctionnement), merci
                        de passer par le formulaire de contact support de
                        celle-ci.
                      </small>
                      <small className="col-12 mandatory">
                        Tous les champs sont obligatoires sauf mention
                        contraire.
                      </small>
                      <form onSubmit={sendMail} className="text-primary ">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputFonction">
                            <small>Quel est le sujet de votre demande ?</small>
                          </label>
                          <SelectList
                            options={options}
                            onChange={onChangeSelect}
                            value={motifSelect || ''}
                            placeholder="Sélectionner un motif"
                            className="text-primary "
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputFonction">
                            <small>Pour quel motif ?</small>
                          </label>
                          <SelectList
                            options={optionsSousMotif}
                            onChange={onChangeSelectSousMotif}
                            value={sousMotifSelect || ''}
                            placeholder="Sélectionner un sous-motif"
                            className="text-primary "
                            noOptionsMessage={() =>
                              'Veuillez selectionner un motif !'
                            }
                          />
                        </div>
                        {optionsMotifNv3.length !== 0 && (
                          <div className="form-group col-md-6">
                            <label htmlFor="inputFonction">
                              <small>Que souhaitez-vous faire ? </small>
                            </label>
                            <SelectList
                              options={optionsMotifNv3}
                              onChange={onChangeSelectMotifNv3}
                              value={motifNv3Select || ''}
                              placeholder="Sélectionner une option"
                              className="text-primary "
                              noOptionsMessage={() =>
                                'Veuillez selectionner un sous-motif !'
                              }
                            />
                          </div>
                        )}
                        <div className="form-group col-md-11">
                          <label
                            htmlFor="formControlTextarea1"
                            className="textareaLabel"
                          >
                            <small>Saisissez votre message</small>
                          </label>
                          <textarea
                            className="form-control h3 text-primary"
                            id="formControlTextarea1"
                            rows="9"
                            value={textareaValue}
                            required
                            onChange={(e) => setTextareaValue(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="form-group col-12">
                          <label htmlFor="inputFile">
                            <small>Pièce jointe (facultatif)</small>
                          </label>
                          <div className="custom-file-content">
                            <div className="custom-file col-12 col-md-6">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFileLang"
                                lang="fr"
                                onChange={onFilesChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileLang"
                              >
                                <PieceJointe />
                                {fileName === '' ? 'Aucun fichier' : fileName}
                              </label>
                            </div>
                            <button
                              type="button"
                              className="btn text-primary border border-primary rounded-pill fw-bold d-none d-md-block"
                              onClick={() => {
                                $('.custom-file-input').click();
                              }}
                            >
                              Ajouter un fichier
                            </button>
                            <small>Taille maximale : 20 Mo</small>
                            <small>
                              Les formats autorisés sont jpg et png.
                            </small>
                            {errorSize && (
                              <small className="text-error">
                                Fichier trop volumineux
                              </small>
                            )}
                            {errorFormat && (
                              <small className="text-error">
                                Le format du fichier n'est pas pris en charge
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="">
                          <LoadingButton
                            type="submit"
                            classes="btn btn-primary rounded-pill send"
                            text="Envoyer mon message"
                            loading={isLoadingPostEmailSupport}
                            spinnerHeight="25"
                            disabled={buttonIsDisabled}
                          />
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="send col-12">
                      <h4 className="fw-bold">
                        Votre message a bien été envoyé.
                      </h4>
                      <p className="text">
                        Nous allons prendre en comtpe votre message et vous
                        recontacterons dans les plus brefs délais.
                      </p>
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={() => setMessageSend(false)}
                      >
                        Fermer
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="phone"
                  role="tabpanel"
                  aria-labelledby="phone-tab"
                >
                  <div className="contacts col-12">
                    <div className="contact">
                      <div className="title">Utilisation de votre solution</div>
                      <p>
                        Pour une question relative à votre solution{' '}
                        <small className="d-block text-placeholder">
                          (Gestion des bénéficiaires, gestion du contenu,
                          gestion de la communication, etc)
                        </small>
                      </p>
                      <span>04 76 63 31 65</span>
                      <small className="d-block text-primary">
                        (Du lundi au samedi de 9h à 17h30.)
                      </small>
                      <br />
                      <p>
                        Pour toute question relative à votre contrat{' '}
                        <small className="d-block text-placeholder">
                          (Factures, Modification du mode de règlement, etc)
                        </small>
                      </p>
                      <span>Veuillez utilisez le formulaire de contact</span>
                    </div>
                    {dataMainResponsable && (
                      <div className="contact">
                        <div className="title">Vie de votre contrat</div>
                        <p>Pour une question sur votre contrat avec nous.</p>
                        <span>
                          {dataMainResponsable.telephone
                            ? dataMainResponsable.telephone
                            : '01 74 31 87 30'}
                        </span>
                      </div>
                    )}
                    <div className="contact">
                      <div className="title">Vos bénéficiaires et Meyclub</div>
                      <p>Pour une commande passée ou en cours.</p>
                      <span>01 79 62 15 13</span>
                      <br />
                      <br />
                      <p>Pour passer une commande par téléphone.</p>
                      <span>0825 808 000</span>
                      <small className="d-block text-primary">
                        (0,18 centimes d'euros par minute du lundi au vendredi
                        de 9h à 18h)
                      </small>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
