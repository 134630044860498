import React, { useState, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Booking from '@components/Booking/Booking';
import MiniCardRdv from '@components/Card_rdv/mini_card_rdv';

import GoogleTagManagement from '@GoogleTagManagement';

import {
  useSkynetAction,
  useSkynetMainResponsable,
  useSkynetActionsList1FromDate,
  useSkynetPutAction,
} from '@hooks/useApi';

import { convertTimeToMs } from '@helpers';

import { ReactComponent as Delete } from '@img/icons/delete.svg';

import './postponeAppointment.scss';
import PostponeAppointmentCongrat from './postponeCongrat';

export default function PostponeAppointment() {
  const [dataRecap, setDataRecap] = useState(null);
  const [date, setDate] = useState(null);
  const [congrat, setCongrat] = useState(false);
  const [notSameCDC, setNotSameCDC] = useState(false);
  const [isPhysical, setIsPhysical] = useState(false);
  const [isDeadline, setIsDeadline] = useState(false);
  const { rdvId } = useParams();

  const queryClient = useQueryClient();

  const {
    data: dataAction,
    isLoading: isLoadingAction,
    isError: isErrorAction,
  } = useSkynetAction(rdvId);

  const {
    data: dataMainResponsable,
    isLoading: isLoadingMainResponsable,
    isError: isErrorMainResponsable,
  } = useSkynetMainResponsable({
    cacheTime: convertTimeToMs(1, 'day'),
    staleTime: convertTimeToMs(1, 'day'),
  });

  const {
    data: dataActionList,
    isLoading: isLoadingActionList,
    isError: isErrorActionList,
  } = useSkynetActionsList1FromDate(dataAction, {
    enabled: !!dataAction,
  });

  const {
    mutate: mutatePutAction,
    isLoading: isLoadingPutAction,
    data: dataPutAction,
  } = useSkynetPutAction({
    onSuccess: (data) => {
      setCongrat(true);

      queryClient.invalidateQueries({
        queryKey: ['skynet-actions-list'],
      });

      queryClient.invalidateQueries({
        queryKey: ['skynet-next-rdv'],
      });

      queryClient.invalidateQueries({
        queryKey: ['data-distance'],
      });

      queryClient.invalidateQueries({
        queryKey: ['data-presentiel'],
      });
    },
  });

  useEffect(() => {
    if (dataAction) {
      if (dataAction.sousTypeAction === 'RDV physique') {
        setIsPhysical(true);
      }

      const dayDiff = dayjs(dataAction.dateDebut).diff(dayjs(), 'hour');
      if (dayDiff < 48) setIsDeadline(true);
    }
  }, [dataAction]);

  useEffect(() => {
    if (
      dataMainResponsable?.idResponsable !==
      dataAction?.utilisateurAssignes[0].id
    )
      setNotSameCDC(true);
  }, [dataMainResponsable, dataAction]);

  useEffect(() => {
    if (dataActionList) {
      let dataRecap = dataActionList.actions[0];

      if (dataRecap.sousTypeAction === 'RDV à distance') {
        dataRecap.format = '0';
      } else if (dataRecap.sousTypeAction === 'RDV demande de rappel') {
        dataRecap.format = '2';
      } else {
        dataRecap.format = '1';
      }

      setDataRecap(dataRecap);
    }
  }, [dataActionList]);

  const onClickNext = async () => {
    if (isLoadingAction || isLoadingMainResponsable || isLoadingActionList)
      return;

    const dataMainResponsableParsed = JSON.parse(dataMainResponsable);

    if (rdvId && date && dataMainResponsableParsed.idResponsable)
      mutatePutAction({
        rdvId: rdvId,
        dataActionList: dataActionList,
        action: JSON.stringify({
          dateDebut: date,
        }),
      });
  };

  return !congrat ? (
    <div className="w-100 postpone-appointment">
      <GoogleTagManagement titlePage="Report du rendez-vous" />
      <div className="header-create-appointment">
        <h3 className="title_page">Reporter un rendez-vous</h3>
      </div>
      <div className="row">
        <div className="col-12 rect_info">
          <div className="col-12">
            <div className="row">
              {isErrorAction || isErrorMainResponsable || isErrorActionList ? (
                <div>
                  <h5 className="mb-0 text-error">
                    <span className="fw-bold">
                      <i className="fas fa-exclamation-triangle text-error me-2"></i>
                      Erreur :
                    </span>{' '}
                    Un problème est survenu lors de la récupération des données.
                  </h5>
                </div>
              ) : (
                <React.Fragment>
                  <div className="col-12">
                    <div className="header-content-postpone-appointment d-flex justify-content-between">
                      <>
                        <span></span>
                        <Link
                          to={{ pathname: '/appointment' }}
                          className="annuler text-placeholder"
                        >
                          <Delete />
                          Annuler
                        </Link>
                      </>
                    </div>
                  </div>
                  <div className="title text-primary fw-bold text-center">
                    <div>
                      À quand souhaitez-vous reporter votre rendez-vous ?
                      <span>Sélectionnez un horaire</span>
                    </div>
                    <div className="rappel-rdv" data-cy="rappel-rdv">
                      Rappel du rendez-vous initial
                      {true && (
                        <MiniCardRdv
                          data={{
                            dateDebut: dataAction && dataAction.dateDebut,
                            utilisateursAssignes: [
                              dataAction && dataAction.utilisateursAssignes,
                            ],
                          }}
                          hasDashboard={true}
                          hasButton={false}
                          isLoading={isLoadingAction}
                        />
                      )}
                    </div>
                  </div>
                  {dataMainResponsable && notSameCDC && (
                    <div className="text-center w-100">
                      <span className="mb-3 badge badge-info fw-600 text-center">
                        Changement de votre chargé de clientèle :{' '}
                        {dataMainResponsable &&
                          dataMainResponsable.nom +
                            ' ' +
                            dataMainResponsable.prenom}
                      </span>
                    </div>
                  )}
                  <div className="content-postpone-appointment">
                    <Booking
                      buttonName="Valider le report"
                      onClickNext={onClickNext}
                      format={isPhysical ? 1 : 0}
                      motif={!isLoadingAction ? dataAction.motif : null}
                      date={date}
                      setDate={setDate}
                      loading={
                        isLoadingAction &&
                        isLoadingMainResponsable &&
                        isLoadingActionList
                      }
                      loadingButton={isLoadingPutAction}
                      isDeadline={isDeadline}
                      infoResponsable={dataMainResponsable}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    dataRecap && (
      <div className="w-100 postpone-appointment">
        <div className="header-create-appointment">
          <h3 className="title_page">Reporter un rendez-vous</h3>
        </div>
        <div className="row">
          <div className="col-12 rect_info">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <PostponeAppointmentCongrat dataRecap={dataPutAction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
