import React from 'react';

import { Redirect, useLocation, useParams } from 'react-router-dom';

export default function PaymentRedirection() {
  const { status } = useParams();
  const query = new URLSearchParams(useLocation().search);

  const paymentResponseData = {
    type: status,
    amount: query.get('amount'),
    discountedAmount: query.get('discountedAmount'),
  };

  return (
    <Redirect
      push
      to={{
        pathname: '/dashboard',
        state: { paymentResponseData: paymentResponseData },
      }}
    />
  );
}
