import React from 'react';

import { LinkButton } from '@molecules';

import ImageWebinar from '@img/img_webinar.png';

import './webinar.scss';

const Webinar = ({ user }) => {
  return (
    <>
      <div className="col-12 card-webinar">
        <h5 className="fw-bold text-primary rect_info_title">
          Les webinars du mois
        </h5>

        <img
          src={ImageWebinar}
          alt="Une femme devant un ordinateur en train d'écrire"
          className="w-100 mb-4 px-4"
        />

        <div className="d-flex flex-column align-items-center justify-center w-100">
          <div className="text-primary description-card-webinar d-flex mb-4">
            Vous souhaitez monter en compétences sur votre logiciel ? <br />
            Tous les mois, participez à nos webinars et apprenez à utiliser
            votre solution avec nos équipes.
          </div>
        </div>
      </div>

      <LinkButton
        to={{
          pathname: user?.platformLink,
        }}
        target="_blank"
        data-cy="button-webinar"
        className="btn btn-webinar btn-primary rounded-pill btn-block"
        label="S'inscrire aux webinars"
      />
    </>
  );
};

export default Webinar;
