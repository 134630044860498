import Holidays from 'date-holidays';
import dayjs from 'dayjs';

export const isSameMonth = (date, month) => {
  return dayjs(date).isSame(month, 'month');
};

export const switchToNextMonthIfNoAvailableDates = (
  availableDates,
  months,
  selectedMonth,
  onClickMonth
) => {
  let findIndexMonth = months.findIndex(
    (month) => month.monthId === selectedMonth
  );

  //Tests s'il n'y a pas de creneaux disponibles et switch sur la prochain mois
  if (availableDates.length <= 0 && findIndexMonth !== months.length - 1) {
    if (findIndexMonth !== -1) {
      onClickMonth(
        months[months.findIndex((month) => month.monthId === selectedMonth) + 1]
          .monthId
      );
    }
  }
};

export const getAvailableDates = (startDate, interval, availabilityView) => {
  let availableDates = [];
  const availabilityViewTab = [...availabilityView];
  const hd = new Holidays('FR');
  const isBefore18H = (calc) =>
    !dayjs(dayjs(calc).add(interval, 'minutes')).isAfter(
      dayjs(calc).set('hour', 18).set('minute', 0),
      'minute'
    );
  const isAfter9H = (calc) => parseInt(calc.format('H')) >= 9;
  const isNotHoliday = (calc) => !hd.isHoliday(calc.format('YYYY-MM-DD'));
  const isNotWeekend = (calc) =>
    calc.format('d') !== '6' &&
    calc.format('d') !== '0' &&
    calc.format('d') !== '1';
  const isMondayAfter14H = (calc) =>
    calc.format('d') === '1' && calc.format('HH') >= 14;

  const isNotBetween12HAnd13H = (calc) =>
    !dayjs(calc).isBetween(
      dayjs(calc).set('hour', 11).set('minute', 59),
      dayjs(calc).set('hour', 13).set('minute', 0),
      'minute'
    );
  const isMeetingNeverStopBetween12HAnd13H = (calc) =>
    !dayjs(dayjs(calc).add(interval, 'minutes')).isBetween(
      dayjs(calc).set('hour', 12).set('minute', 0),
      dayjs(calc).set('hour', 13).set('minute', 1),
      'minute'
    );
  const isNotInTheNext24H = (calc) =>
    !dayjs(calc).isBetween(dayjs(), dayjs().add(24, 'hour'), 'minute');

  const isNotInTheNext24HAndAfterTheWeekend = (calc) => {
    if (dayjs().format('d') === '5') {
      return !dayjs(calc).isBetween(dayjs(), dayjs().add(72, 'hour'), 'minute');
    } else {
      return isNotInTheNext24H(calc);
    }
  };

  availabilityViewTab.forEach((item, index) => {
    if (parseInt(item) === 0) {
      const calc = dayjs(startDate).add(interval * index, 'minutes');
      if (
        isBefore18H(calc) &&
        isAfter9H(calc) &&
        isNotHoliday(calc) &&
        isNotInTheNext24HAndAfterTheWeekend(calc) &&
        isNotBetween12HAnd13H(calc) &&
        isMeetingNeverStopBetween12HAnd13H(calc)
      ) {
        if (isNotWeekend(calc)) {
          availableDates.push(calc.format());
        } else if (isMondayAfter14H(calc)) {
          availableDates.push(calc.format());
        }
      }
    }
  });
  return availableDates;
};

export const createSliderWeek = (
  selectedMonth,
  nbDaysInSelectedMonth,
  setListDays,
  listDays
) => {
  let weeks = [];
  let weeksTab = [];
  let firstDay = dayjs().isSame(selectedMonth, 'month') ? dayjs().date() : 1;

  for (let i = firstDay; i <= nbDaysInSelectedMonth; i++) {
    let day = dayjs(selectedMonth).date(i);
    let dayOfWeek = day.day();

    if (i === firstDay && dayOfWeek > 0 && dayOfWeek < 6) {
      for (let j = dayOfWeek - 1; j >= 1; j--) {
        let dayBefore = day.clone().subtract(j, 'day');
        weeksTab.push({ date: dayBefore.format(), isHoliday: true });
        weeks.push(divWeek(dayBefore, true));
      }
    }

    if (dayOfWeek > 0 && dayOfWeek < 6) {
      weeksTab.push({ date: day.format(), isHoliday: false });
      weeks.push(divWeek(day));
    }

    if (i === nbDaysInSelectedMonth && dayOfWeek < 5) {
      for (let k = 1; k <= 5 - dayOfWeek; k++) {
        let dayAfter = day.add(k, 'day');
        weeksTab.push({ date: dayAfter.format(), isHoliday: true });
        weeks.push(divWeek(dayAfter, true));
      }
    }
  }

  if (listDays === null) setListDays(weeksTab);

  return weeks;
};

const divWeek = (day, disabled = false) => {
  return (
    <div key={day}>
      <div
        className={`date ${disabled ? 'disabled' : ''}`}
        data-day={day.format('YYYY-MM-DD')}
      >
        {day.locale('fr').format('dddd')}
        <span>{day.locale('fr').format('DD MMM')}</span>
      </div>
    </div>
  );
};
